import React , {  useState , useEffect} from 'react';
import { useNavigate, useParams} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import './style_school.css';
import {useApiData,IconsColor} from "../functions/useApiData"

// import {Card} from "../components/cards/cards";
// import LoadingUI from '../components/loading/loadingui';
// import ImgF003 from './../img/BFimg/F003.jpg';  //sadmin.png avatar.jpeg
// import Avatar from './../img/avatar.jpeg';  //sadmin.png avatar.jpeg temp02.png
// import tmpimg from './../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
//import star_anki from './../img/star_anki.png';  //sadmin.png avatar.jpeg temp02.png

import MDEditor, {
    commands,
    ICommand,
    EditorContext
  } from "@uiw/react-md-editor";

function DeckForm(props) {
    // Estados para almacenar los valores de los campos
    const [email, setEmail] = useState('email@gmail.com');
    const [roleId, setRoleID] = useState(8);
    const [templateHTML, setTemplateHTML] = useState(0);
    const [note, setNote] = useState('note');
    const [groupid, setGroupID] = useState(0);
    const [dateSt, setDateSt] = useState('2024-02-22');
    const [colorT, setColorT] = useState('#d3dbd5'); // Valor inicial del color en negro
    const [colorB, setColorB] = useState('#236'); // Valor inicial del color en negro

    const activeProjectId = localStorage.getItem('projectid');

    const [classGroup, setClassGroup] = useState([]);
    const [countclassGroup, setCountClassGroup] = useState(0);

    //console.log('activeProjectId:', activeProjectId);

    //const { data, loading, error } = useApiData('GET', apiEndpoint);
    const apiEndpoint = '/school/getGroups/' + activeProjectId;
    const { data: ClassDesktop, loading: loadingDesktop, error: ClassDesktopError } =  useApiData('GET', apiEndpoint);

    useEffect(() => { 
        if (ClassDesktop.groups) {
            setClassGroup(ClassDesktop.groups)
            setCountClassGroup(ClassDesktop.count)

            console.log ("setClassGroup data", ClassDesktop)
        }
    }, [ClassDesktop]); // AUTOLOAD


    const navigate = useNavigate();
    // const login_token = useSelector((state)=> state.counter.user.login_token);
    const login_token = localStorage.getItem('login_token');

     // Manejadores de cambios para actualizar los estados
     const handleNameChange = (e) => {
        setEmail(e.target.value);
    };

    const handleLevelChange = (e) => {
        setRoleID(e.target.value);
    };

    const handleLNGNameChange = (e) => {
        setNote(e.target.value);
    };

    const handleGroupIDChange = (e) => {
        setGroupID(e.target.value);
        console.log ("e.target.value",e.target.value)
    };

    const handleDateStChange = (e) => {
        setDateSt(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes hacer algo con los valores, como enviarlos a un servidor
        // console.log('email:', email);
        // console.log('roleId:', roleId);
        // console.log('html:', templateHTML);
        // console.log('note:', note);
        // console.log('dateSt:', dateSt); 
        // console.log('login_token:', login_token);
        // console.log('projectid:', activeProjectId);
        // console.log('groupid:', groupid);

        const userData = {
            projectid: activeProjectId,
            email: email,
            roleId: roleId,
            html: templateHTML,
            note: note,
            dateSt: dateSt,
            groupid: groupid,
        };

        fetch('https://www.nebo.inupline.com/api/web/invite/add', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData)
        })

      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("API->api/invite/add", response)
            navigate(`/projects`)
            // dispatch(application.setUpdateTime(response.update));
          } else {
            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
            console.log("ERROR api/invite/add-" , response)
        //setLoading(false)
        }
      });
    };

    return (
        <div className="form_0">
            <form onSubmit={handleSubmit}>
                {/* <input type="hidden" name="userid" readOnly={"user_id"} /> */}
                <label className="desc" id="title_l" htmlFor="title">
                    EMAIL кому надіслати запрошення:
                </label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="title" name="title" type="text" placeholder="NAME" value={email} required onChange={handleNameChange}/>
                </div>

                <label className="desc" id="typeid_l" htmlFor="typeid">
                    Роль кандидата:
                </label>
                <div className="input_field select_option">
                    <select id="typeid" value={roleId} onChange={handleLevelChange}>
                        <option value={"8"} key={0}>Student</option>
                        <option value={"7"} key={1}>Profesor</option>
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <label className="desc" id="typeid_l" htmlFor="typeid">
                    Шаблон запрошення:
                </label>
                <div className="input_field select_option">
                    <select id="typeid" value={templateHTML} onChange={handleLevelChange}>
                        <option value={"0"} key={0}>Standart</option>
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <label className="desc" id="typeid_l" htmlFor="typeid">
                    Короткий опис:
                </label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-tag"></i></span>
                    <input id="note" name="note" type="text" placeholder="Idioma" value={note} onChange={handleLNGNameChange}/>
                </div>

                <label className="desc" id="typeid_l" htmlFor="typeid">
                    Пряме запрошення у клас ( Всього класів: {countclassGroup} ):
                </label>
                <div className="input_field select_option">
                    <select value={groupid} onChange={handleGroupIDChange}>
                            <option value={null}>
                                Весь проект (Запрошення для персоналу )
                            </option>
                        {classGroup.map((filename, index) => (
                            <option key={index} value={filename.groupid}>
                                {filename.cursname} - {filename.groupname} ({filename.cursnote} / {filename.groupid})
                            </option>
                        ))}
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <div className="input_field"> 
                    <label className="desc" htmlFor="start_date">Коли (дата) надіслати запрошення:</label>
                    <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sign-in"></i></span>
                        <input type="date" id="start_date" name="trip-start" value= {dateSt} min="2024-01-01" max="2030-12-31" onChange={handleDateStChange}/>
                    </div>
                </div>

                <input className="button" type="submit" value="SAVE"/>
            </form>

            {/* <div style={{top: "0px", left: "500px", position: "absolute", width: "280px", color:"#b3b3b3", padding: "15px"}}>
                PREVIEW
                <div id = {"Group_" + props.ids} className={"card3 "}  style={{ backgroundColor: colorB, margin: "15px"}}>
                    <div className="dclbl04b" style={{ color: colorT}} >{roleId}</div>
                    <div className="dclbl05b" style={{ color: colorT}} >{email}</div>
                </div>
            </div> */}
        </div> 
    );
}

function SchoolDecks(props) {
    //const { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id
    const navigate = useNavigate();

    const activeProjectId = localStorage.getItem('projectid');

    const handleHome = (childValue) => {
        navigate(-1);
    };

    return (
        <div>
            <div>
                <div className="project_urls"  >
                    <div className="aButtonClassBack" onClick={handleHome}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>
                    <div className="cLabelClassHeaderBar">Add invite</div>
                    {/* <div className="aButtonClassAdd" onClick={handleHome}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassDelete" onClick={() => handleDelDeck(cid)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassImport" onClick={handleHome}><i className="fa fa-upload" aria-hidden="true"></i></div>
                    <div className="aButtonClassConfig" onClick={handleHome}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                </div>
                    {/* <DeckSteps /> */}
                    <DeckForm pid = {activeProjectId}/>
                    {/* <MDEditor.Markdown className='PreviewPageInvite' source={htmlContent} /> */}
            </div>
        </div>
    );
}

export default SchoolDecks;
