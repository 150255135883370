import React , {  useState , useEffect}  from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from '../../img/logo_company.png';  //sadmin.png
import lock_icon from '../../img/lock.png';  //sadmin.png


export function InviteList(props) {
    //WELCOM PAGE
    const [imgIco, setImgIco] = useState();
    //const dispatch = useDispatch();

    const openLinkURL = (url) => {
        props.selid (props.json.ID)
    }

    return (
        <li className="lisinmarker" >
            <figure className="userlist"  onClick={openLinkURL} >
                <span className="linenumero">{props.index}</span>
                {/* <span className="outdate"><i className="fa fa-share-square-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.CHECKIN}</span>
                <span className="acdate"><i className="fa fa-check-square-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.CHECKOUT}</span> */}

                <span className="status">
                    {/* {props.json.CLIENTE == 0 && <i className="fa fa-exclamation-circle" style={{color:"blue"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE == 1 && <i className="fa fa-paper-plane" style={{color:"grey"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>} */}
                    {/* {props.json.CLIENTE == props.json.VIAJEROS && <i className="fa fa-check-circle" style={{color:"green"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE > props.json.VIAJEROS && <i className="fa fa-check-circle" style={{color:"red"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE < props.json.VIAJEROS && <i className="fa fa-check-circle" style={{color:"orange"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>} */}
                    <img className="plogo" src={lock_icon} alt="logo" style={{width: "15px"}} />
                    {/* {props.json.CLIENTE == 3 && <i className="fa fa-times-circle" style={{color:"red"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE == 4 && <i className="fa fa-clock-o" style={{color:"orange"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>} */}
                </span> 

                <span className="email"> <i className="fa fa-envelope" style={{color:"green"}}></i>&nbsp;&nbsp;&nbsp;{props.json.NAME}&nbsp;{props.json.APEDIDO}&nbsp;{props.json.APEDIDO2}</span>
            
            </figure>
        </li>
    );
}

const translations = {
    en: {
      title: "TRAVELERS ENTRY PART",
      title2: "SHEET - REGISTER",
      title3: "HOLDER AND ESTABLISHMENT DATA",

      label1: "NAME OF THE ESTABLISHMENT",
      label2: "NAME AND SURNAME OF THE OWNER",
      label3: "OWNER'S EMAIL",
      label4: "FULL ADDRESS OF THE PROPERTY",
      label5: "ROOMS",
      label6: "INTERNET CONNECTION",
      label7: "RESERVATION",
      label8: "Check-IN",
      label9: "Check-OUT",
      label10: "PEOPLE",
      label11: "TRAVELERS",
      label12: "ADD TRAVELER",
      label13: "DOCUMENTARY REGISTRATION OBLIGATIONS",
      label14: "The obligated parties must maintain a digital record that includes the data outlined in ",
      label15: "of",
      label16: "dated October 26, including, where applicable, data of individuals under fourteen years of age.",
      label17: "The obligated parties must register and retain the data of their users collected in the course of their activity.",
      label18: "The data in the digital record must be kept for a period of three years, starting from the completion of the contracted service or provision.",
    
    },
    es: {
      title: "PARTE DE ENTRADA DE VIAJEROS",
      title2: "HOJA - REGISTRO",
      title3: "DATOS DEL TITULAR Y ESTABLECIMIENTO",

      label1: "NOMBRE DEL ESTABLECIMIENTO",
      label2: "NOMBRE Y APELLIDO PROPIETARIO",
      label3: "MAIL DEL PROPIETARIO",
      label4: "DIRECCION DE LA VIVIENDA COMPLETA",
      label5: "HABITACIONES",
      label6: "CONEXIÓN A INTERNET",
      label7: "RESERVA",
      label8: "CheckIN",
      label9: "CheckOUT",
      label10: "PERSONAS",
      label11: "VIAJEROS",
      label12: "ANADIR VIAJERO",
      label13: "OBLIGACIONES DE REGISTRO DOCUMENTAL",
      label14: "Los sujetos obligados habrán de llevar un registro informático en el que consten los datos que se relacionan en el ",
      label15: "del",
      label16: "de 26 de octubre, incluidos, en su caso, los datos de las personas menores de catorce años.",
      label17: "Los sujetos obligados deberán registrar y conservar aquellos datos de sus usuarios, que recaben en el ejercicio de su actividad.",
      label18: "Los datos del registro informático deberán conservarse durante un plazo de tres años a contar desde la finalización del servicio o prestación contratada.",

    },
    uk: {
      title: "ЧАСТИНА ВХОДУ ПОДОРОЖНИХ",
      title2: "АРКУШ – РЕЄСТР",
      title3: "ДАНІ ВЛАСНИКА ТА ЗАКЛАДУ",

      label1: "НАЗВА ЗАКЛАДУ",
      label2: "ІМ'Я ТА ПРІЗВИЩЕ ВЛАСНИКА",
      label3: "EMAIL ВЛАСНИКА",
      label4: "ПОВНА АДРЕСА ЖИТЛА",
      label5: "КІМНАТИ",
      label6: "ПІДКЛЮЧЕННЯ ДО ІНТЕРНЕТУ",
      label7: "БРОНЮВАННЯ",
      label8: "CheckIN",
      label9: "CheckOUT",
      label10: "ЛЮДЕЙ",
      label11: "МАНДРІВНИКИ",
      label12: "ДОДАТИ МАНДРІВНИКА",
      label13: "ОБОВ'ЯЗКИ ЩОДО ДОКУМЕНТАЛЬНОЇ РЕЄСТРАЦІЇ",
      label14: "Особи, зобов’язані це робити, повинні вести електронний реєстр, який містить дані, зазначені у ",
      label15: "до",
      label16: "від 26 жовтня, включаючи, за необхідності, дані осіб віком до чотирнадцяти років.",
      label17: "Зобов’язані особи повинні реєструвати та зберігати дані своїх користувачів, зібрані під час здійснення своєї діяльності.",
      label18: "Дані електронного реєстру повинні зберігатися протягом трьох років з моменту завершення надання послуги або виконання контракту.",
    },
  };


function ParteViajeros() {
    // Отримуємо параметр numero_reserva з URL
    let params = useParams()
    const navigate = useNavigate();
    //console.log(params.reservaId) 

    const activeParteId = params.reservaId;
  
    const [existReserva, setExistReserva] = useState(true);

    const [viajeroValor0, setViajeroValor0] = useState('');
    const [viajeroValor1, setViajeroValor1] = useState(null);
    const [viajeroValor2, setViajeroValor2] = useState('');
    const [viajeroValor3, setViajeroValor3] = useState('');
    const [viajeroValor4, setViajeroValor4] = useState('');
    const [viajeroValor5, setViajeroValor5] = useState('');

    const [viajeroValor6, setViajeroValor6] = useState('');
    const [viajeroValor7, setViajeroValor7] = useState('');
    const [viajeroValor8, setViajeroValor8] = useState('');

    const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + viajeroValor5 + '/logo.png');
    const [inviteList, setInviteList] = useState([]); // IMPORT Group / Course

    useEffect(() => {
    // перевірити валідність токену
      fetch('https://www.nebo.inupline.com/api/web/vut/getReservaParte/' + activeParteId, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': '555888666999',
          },
      })
          .then(response => response.json())
          .then(response => {
                console.log("API getReservaParte:" , response)
              if (response.status===200) {
                  setViajeroValor1(response.PARTE_NUMBER);
                  localStorage.setItem('parteid', response.PARTE_NUMBER)
              } else if (response.status===409) {
              } else if (response.status===404) {
                setExistReserva(false)
            }
          });  
      
    }, [activeParteId]); // AUTOLOAD

    useEffect(() => {
        if(viajeroValor1){
              fetch('https://www.nebo.inupline.com/api/web/vut/getPartesViajeros/' + viajeroValor1, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': '555888666999',
                  },
              })
                  .then(response => response.json())
                  .then(response => {
                      
                      if (response.status===200) {
                          console.log("type OK activeParteId-" , response)
                          setViajeroValor0(response.PARTE[0].REFCONTRATO);
                          setViajeroValor2(response.PARTE[0].CHECKIN);
                          setViajeroValor3(response.PARTE[0].CHECKOUT);
                          setViajeroValor4(response.PARTE[0].CLIENTE);
                          setViajeroValor5(response.PARTE[0].PROJECTID);
                          setInviteList(response.PARTE[0].VIAJEROS);
                          setViajeroValor6(response.PARTE[0].HABITACIONES);
                          setViajeroValor7(response.PARTE[0].INTERNET);
                          setViajeroValor8(response.PARTE[0].PROJECTID);

                          setSelectedPhoto("/img/projects/" + response.PARTE[0].PROJECTID + '/logo.png');
                        
                      } else if (response.status===409) {
                          console.log("type KO-activeParteId:" , response)
                      }
                  });  

                  console.log("ARRAY:" ,  inviteList.length)
                }
        }, [viajeroValor1]); // AUTOLOAD

        const handlePartedValue = (childValue) => {
            localStorage.setItem('viajeroid', childValue)
            navigate(`/parteviajero`);
        };

    const [language, setLanguage] = useState("es");

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);

        localStorage.setItem('language', e.target.value)
    };

  return (
    <div style={{padding: "15px"}}>
    {/* {existReserva ?  */}
<div className="form_0">
    <select value={language} onChange={handleLanguageChange} style={{ position: "absolute", right: "20px" , width:"200px"}}>
        <option value="en">English</option>
        <option value="es">Español</option>
        <option value="uk">Українська</option>
    </select></div>

    {existReserva ? ( <div>

        {!selectedPhoto ? ( <img className="plogo" src={logo} alt="logo" />):(
            <div className="plogo" 
                style={{width: "100px", height: "100px", backgroundImage:  `url(${selectedPhoto})`, backgroundSize: "100px 100px"}} >
            </div>
        )} 

      <h2 >{translations[language].title}</h2> 
      Decreto 933/2021
      <br />{translations[language].title2}
      <br />
        <div className="project_urls"  >
            <div className="cLabelClassHeaderBar">{translations[language].title3}</div>
        </div>
        <div style={{padding: "0 25px"}}>
            {/* <h3>DATOS DEL TITULAR Y ESTABLECIMIENTO / OWNER AND ESTABLISHMENT DATA:</h3> */}
            {translations[language].label1}: <strong>FARO28</strong>
            <br />{translations[language].label2}: <strong>SERHII BILOUS</strong>
            <br />{translations[language].label3}: <strong>28faro@gmail.com</strong>
            <br />{translations[language].label4}: <strong><a href="https://maps.app.goo.gl/53pWtY1Npo66P1tA7?g_st=com.google.maps.preview.copy" target="_blank" rel="noopener noreferrer">Carrer Riu Palància, 28, planta 4, piso 10 , Sueca, España </a></strong>
            
            {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
            </div>
        <div className="project_urls"  >
            <div className="cLabelClassHeaderBar">DATOS de RESERVA</div>
        </div>
        <div style={{padding: "0 25px"}}>
            {translations[language].label5}: <strong>{viajeroValor6}</strong>
            <br />PLATAFORMA: <strong>booking.com</strong>
            <br />{translations[language].label6}: <strong>{viajeroValor7===1 ? "YES": "NO"}</strong>
            <br />{translations[language].label7}: <strong>{viajeroValor0}</strong>
            <br />{translations[language].label8}: <strong>{viajeroValor2}</strong>
            <br />{translations[language].label9}: <strong>{viajeroValor3}</strong>
            <br />{translations[language].label10}: <strong>{viajeroValor4}</strong>
            </div>
      <div>
        <div className="project_urls"  >
            {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
            <div className="cLabelClassHeaderBar">{translations[language].label11} </div>
        </div>

        <ul className="grid_invite_user">
                {inviteList && inviteList.map((itemg, index) => ( 
                    <InviteList 
                        key = {index} 
                        index = {index+1}
                        json={itemg} 
                        selid={handlePartedValue} // Click Function
                    />
                ))} 

                {viajeroValor4 > inviteList.length && (
                    <li className="lisinmarker">
                        <figure className="userlist" onClick={() => handlePartedValue("NEW")}>
                            <span className="linenumero"><b>+</b></span>
                            <span className="email">
                                <i className="fa fa-envelope" style={{ color: "green" }}></i>
                                <b>{translations[language].label12}</b>
                            </span>
                        </figure>
                    </li>
                )}
        </ul>    
    </div>
      
    <div className="project_urls"  >
        <div className="cLabelClassHeaderBar">{translations[language].label13}</div>
    </div>
    <div style={{padding: "0 25px"}}>
        <p>{translations[language].label14}<a href="https://www.boe.es/buscar/act.php?id=BOE-A-2021-17461#ai" target="_blank" rel="noopener noreferrer">Anexo I </a> {translations[language].label15} <a href="https://www.interior.gob.es/opencms/es/servicios-al-ciudadano/tramites-y-gestiones/seguridad/registro-documental-de-viajeros/partes-de-entrada-y-obligaciones-de-registro-documental/" target="_blank" rel="noopener noreferrer">Real Decreto 933/2021,</a> {translations[language].label16}</p>
        <p>{translations[language].label17}</p>
        <p>{translations[language].label18}</p>
    </div>
    </div> ) : ( <div>Reserva no existe </div>)}
    
    </div>
  );
}

export default ParteViajeros;


{/* <IfModule mod_rewrite.c>
  RewriteEngine On
  RewriteBase /
 
  
  # RewriteRule ^api api/index.php [L]
  # RewriteRule ^api api/index.php [L]
  

  # Переадресація на https://www.nebo.inupline.com/api/app
  RewriteRule ^api/app api/app/index.php [L]

  # Переадресація на https://www.nebo.inupline.com/api/web
  RewriteRule ^api/web api/web/index.php [L]

  # Переадресація на https://www.nebo.inupline.com/api/test
  # RewriteRule ^api/test api/test/index.php [L]
  
  RewriteCond %{HTTP:X-Forwarded-Proto} !https [OR]
  RewriteCond %{HTTP_HOST} ^nebo.inupline.com [NC]
  RewriteRule (.*) https://www.nebo.inupline.com/$1 [R=301,L]

  RewriteRule ^index\.html$ - [L]
  RewriteCond %{REQUEST_FILENAME} !-f
  RewriteCond %{REQUEST_FILENAME} !-d
  # RewriteCond %{REQUEST_FILENAME} !-l
  RewriteRule . /index.html [L]
</IfModule>


AddType text/html .css

# php -- налаштування сервера на зону мадриду (по замовчуванню)
php_value date.timezone 'Europe/Madrid' */}