import React , {  useState , useEffect} from 'react';
import ReactDOM from 'react-dom';
import { useNavigate} from "react-router-dom";  
import './style_school.css';
import {IconsColor} from "../functions/useApiData"

export function DeckForm(props) {
    // Estados para almacenar los valores de los campos
    const [title, setTitle] = useState('GROUP_NAME');
    const [level, setLevel] = useState(0);
    const [flag, setFlag] = useState(236);
    const [note, setNote] = useState('note');
    const [colorT, setColorT] = useState('#d3dbd5'); // Valor inicial del color en negro
    const [colorB, setColorB] = useState('#236'); // Valor inicial del color en negro
    //const [note, setNote] = useState('A2+');

    // const [alphaT, setAlphaT] = useState('0.1'); // Valor inicial del color en negro
    // const [alphaB, setlphaB] = useState('0.1'); // Valor inicial del color en negro
    //const navigate = useNavigate();

    const iconsContext = require.context('./../img/flags/', false, /\.svg$/);
    const svgFiles = iconsContext.keys();

    //const login_token = useSelector((state)=> state.counter.user.login_token);
    const login_token = localStorage.getItem('login_token');
    const activeProjectId = localStorage.getItem('projectid');

    const [langArray, setLangArray] = useState([]);

     // Manejadores de cambios para actualizar los estados
     const handleNameChange = (e) => {
        setTitle(e.target.value);
    };

    const handleLevelChange = (e) => {
        setLevel(e.target.value);
    };

    const handleflagChange = (e) => {
        setFlag(e.target.value);

        console.log("icon flag:", iconsContext(svgFiles[flag])) 
    };

    const handleLNGNameChange = (e) => {
        setNote(e.target.value);
    };

    const handleColorTChange = (e) => {
        setColorT(e.target.value);
    };

    const handleColorBChange = (e) => {
        setColorB(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes hacer algo con los valores, como enviarlos a un servidor
        // console.log('title:', title);
        // console.log('level:', level);
        // console.log('icoAva:', flag);
        // console.log('colort:', colorT); 
        // console.log('colorb:', colorB);
        // console.log('note:', note);

        // console.log('login_token:', login_token);
        // console.log('projectid:', activeProjectId);

        const userData = {
            projectid: activeProjectId,
            title: title,
            level: level,
            icoAva: flag,
            colort: colorT,
            colorb: colorB,
            note: note,
        };

        fetch('https://www.nebo.inupline.com/api/web/school/postAddClass', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("API->postAddClass", response)
            //navigate(`/projects`)
            // dispatch(application.setUpdateTime(response.update));
          } else {
            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
            console.log("ERROR postAddClass-" , response)
        //setLoading(false)
        }
      });

      toggleClass3()

      ReactDOM.render(null, document.getElementById('UserMenuBoxBODY'));


    };

      useEffect(() => {
        const options = document.querySelectorAll('.vodiapicker option');
        const updatedLangArray = [];
    
        options.forEach(option => {
          const img = option.getAttribute('data-thumbnail');
          const text = option.innerText;
          const value = option.value;
    
          const item = (
            <li key={value}>
              <img src={img} alt="" value={value} />
              <span>{text}</span>
            </li>
          );
    
          updatedLangArray.push(item);
        });
    
        setLangArray(updatedLangArray);
      }, []);

      

    return (
        <>
        <div className="project_urls"  >
            <div className="aButtonClassBack" onClick={toggleClass3}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>
            <div className="cLabelClassHeaderBar">Crear una clase</div>
            {/* <div className="aButtonClassAdd" onClick={handleHome}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
            <div className="aButtonClassDelete" onClick={() => handleDelDeck(cid)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
            <div className="aButtonClassImport" onClick={handleHome}><i className="fa fa-upload" aria-hidden="true"></i></div>
            <div className="aButtonClassConfig" onClick={handleHome}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
        </div>
        <div style={{top: "0px", left: "0px", position: "relative", width: "280px", color:"#b3b3b3"}}>
                        <div id = {"Group_" + props.ids} className={"card3 "}  style={{ backgroundColor: colorB, margin: "15px"}}>
                            <img className= "dcFlagLogo" src={"https://www.nebo.inupline.com" + iconsContext(svgFiles[flag])} alt="dcFlagLogo"/>
                            {/* <img className= "dcimg" src={deck} alt="logo "/> */}
                            <div className="dclbl01">CLASESS: 0</div>
                            <div className="dclbl02">STUDENTS: {flag}</div>
                            {/* <div className="dclbl03b" style={{ color: props.colort}} >{props.label}</div> */}
                            <div className="dclbl04b" style={{ color: colorT}} >{title}</div>
                            <div className="dclbl05b" style={{ color: colorT}} >{note}</div>
                            {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> colort */}
                        </div>
                    </div>
                    
        <div className="form_0">
                    

                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="userid" readOnly={"user_id"} />
                
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-font"></i></span>
                            <input id="title" name="title" type="text" placeholder="NAME" value={title} required onChange={handleNameChange}/>
                        </div>

                        <div className="input_field select_option">
                            <select id="typeid" value={level} onChange={handleLevelChange}>
                                <option value={""} key={0}>All Levels</option>
                                <option value={"1"} key={1}>Introductory Level</option>
                                <option value={"2"} key={2}>Basic Level</option>
                                <option value={"3"} key={3}>Intermediate Level</option>
                                <option value={"4"} key={4}>Advanced Level</option>
                                <option value={"5"} key={5}>Expert Level</option>
                            </select>
                            <div className="select_arrow"></div>
                        </div>
                        
                        <div className="input_field select_option">
                            <select value={flag} onChange={handleflagChange}>
                                {svgFiles.map((filename, index) => (
                                    <option key={index} value={index}>{filename}</option>
                                ))}
                            </select>                            
                            <div className="select_arrow"></div>
                        </div>

                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-tag"></i></span>
                            <input id="note" name="note" type="text" placeholder="Idioma" value={note} required onChange={handleLNGNameChange}/>
                        </div>

                        <div className="input_field" > 
                            Text color <input id="lurl" name="lurl" type="color" colorformat="rgba" placeholder="Color" value={colorT} required onChange={handleColorTChange}/>
                            {/* <input type="range" min="0" max="1" step="0.1"/> */}
                        </div>

                        <div className="input_field"> 
                            Back color <input id="lurl" name="lurl" type="color" placeholder="Color" value={colorB} required onChange={handleColorBChange}/>
                        </div>

                        <input className="button" type="submit" value="CREAR"/>
                    </form>

                    
              </div> 
              </>
    );
}

function SchoolDecks() {
    const activeProjectId = localStorage.getItem('projectid');
    //const navigate = useNavigate();

    const handleHome = (childValue) => {
        //navigate(-1);
    };

    return (
        <div>
            <div>
                <div className="project_urls"  >
                    <div className="aButtonClassBack" onClick={handleHome}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>
                    <div className="cLabelClassHeaderBar">Crear una clase</div>
                    {/* <div className="aButtonClassAdd" onClick={handleHome}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassDelete" onClick={() => handleDelDeck(cid)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassImport" onClick={handleHome}><i className="fa fa-upload" aria-hidden="true"></i></div>
                    <div className="aButtonClassConfig" onClick={handleHome}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                </div>

                {/* <DeckSteps /> */}
                <DeckForm pid = {activeProjectId}/>
            </div>
        </div>
    );
}

export default SchoolDecks;

{/* <GroupeForm mydeck = {SchoolGroup} pid = {pid} apimsg = {handleShowMSG} uploadid={handleGetDeck}/> */}

export function EditClass(props) {
    // Форма редагування групи (курсу)
    // Estados para almacenar los valores de los campos
    const [classID, setGiD] = useState(null);
    const [title, setTitle] = useState('GROUP_NAME');
    const [level, setLevel] = useState(0);
    const [flag, setFlag] = useState(236);
    const [note, setNote] = useState('note');
    const [colorT, setColorT] = useState('#d3dbd5'); // Valor inicial del color en negro
    const [colorB, setColorB] = useState('#d3dbd5'); // Valor inicial del color en negro
    //const [note, setNote] = useState('A2+');

    // const [alphaT, setAlphaT] = useState('0.1'); // Valor inicial del color en negro
    // const [alphaB, setlphaB] = useState('0.1'); // Valor inicial del color en negro

    //const class_id = localStorage.getItem('classid');

    const iconsContext = require.context('./../img/flags/', false, /\.svg$/);
    const svgFiles = iconsContext.keys();

    //const login_token = useSelector((state)=> state.counter.user.login_token);
    const login_token = localStorage.getItem('login_token');
    const activeProjectId = localStorage.getItem('projectid');

    const [SchoolClass, setSchoolClass] = useState([]); // IMPORT className
    const [SchoolGroup, setSchoolGroup] = useState([]); // IMPORT Group / Course

    // const apiEndpoint = '/school/getClass/' + class_id;
    // const { data: ClassData, loading: loading, error: ClassError } =  useApiData('GET', apiEndpoint);

    const LoadClass = (e) => {
        fetch('https://www.nebo.inupline.com/api/web/school/getClass/'+ props.class_id, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            // body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response) {
            console.log("API->postAddClass", response)
            // setSchoolClass(response.groups)
            // setSchoolGroup(response.levels)

            if (response.groups ) {
                setSchoolClass(response.groups)
                console.log("ERROR response.groups-" , response.groups)
            }
        
            if (response.levels) {
                setSchoolGroup(response.levels)
                console.log("ERROR postAddClass-" , response.levels)
            }


            //navigate(`/projects`)
            // dispatch(application.setUpdateTime(response.update));
          } else {
            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
            console.log("ERROR postAddClass-" , response)
        //setLoading(false)
        }
      });
    };

    // useEffect(() => { 
    //     //setSchoolGroup(ClassData.levels)
    //     if (ClassData.groups ) {
    //         setSchoolClass(ClassData.groups)
    //         console.log ("getClass groups", ClassData)
    //     }

    //     if (ClassData.levels ) {
    //         setSchoolGroup(ClassData.levels)
    //         console.log ("getClass levels", ClassData)
    //     }
    // }, [ClassData]); // AUTOLOAD

    useEffect(() => {
        LoadClass()

        console.log("class_id",props.class_id)
    }, []); // AUTOLOAD

    useEffect(() => {
        setGiD(SchoolGroup.id)
        setTitle(SchoolGroup.title)     
        setLevel(SchoolGroup.level)
        setFlag(SchoolGroup.icoAva)
        setNote(SchoolGroup.note)
        setColorT(SchoolGroup.colort)
        setColorB(SchoolGroup.colorb)

        //console.log("icon flag:", SchoolGroup.icoAva , SchoolGroup)

        // console.log("icon flag:", iconsContext(svgFiles[SchoolGroup.icoAva])) 

    }, [SchoolGroup]); // AUTOLOAD

    const [langArray, setLangArray] = useState([]);

     // Manejadores de cambios para actualizar los estados
     const handleNameChange = (e) => {
        setTitle(e.target.value);
    };

    const handleLevelChange = (e) => {
        setLevel(e.target.value);
    };

    const handleflagChange = (e) => {
        setFlag(e.target.value);
    };

    const handleLNGNameChange = (e) => {
        setNote(e.target.value);
    };

    const handleColorTChange = (e) => {
        setColorT(e.target.value);
    };

    const handleColorBChange = (e) => {
        setColorB(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('title:', title);
        console.log('level:', level);
        console.log('icoAva:', flag);
        console.log('colort:', colorT); 
        console.log('colorb:', colorB);
        console.log('note:', note);

        console.log('login_token:', login_token);
        console.log('projectid:', activeProjectId);

        const userData = {
            projectid: activeProjectId,
            title: title,
            level: level,
            icoAva: flag,
            colort: colorT,
            colorb: colorB,
            note: note,
        };

        fetch('https://www.nebo.inupline.com/api/web/school/postAddClass/' + props.class_id, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("API->postAddClass", response)
            props.update(response)
          } else {
            console.log("ERROR postAddClass-" , response)
        }
      });

    };


      useEffect(() => {
        const options = document.querySelectorAll('.vodiapicker option');
        const updatedLangArray = [];
    
        options.forEach(option => {
          const img = option.getAttribute('data-thumbnail');
          const text = option.innerText;
          const value = option.value;
    
          const item = (
            <li key={value}>
              {/* <img src={img} alt="" value={value} /> */}
              <span>{text}</span>
            </li>
          );
    
          updatedLangArray.push(item);
        });
    
        setLangArray(updatedLangArray);
      }, []);


    return (
        <>
        <div className="project_urls"  >
            <div className="aButtonClassBack" onClick={toggleClass3}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>
            <div className="cLabelClassHeaderBar">Edit clase</div>
            {/* <div className="aButtonClassAdd" onClick={handleHome}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
            <div className="aButtonClassDelete" onClick={() => handleDelDeck(cid)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
            <div className="aButtonClassImport" onClick={handleHome}><i className="fa fa-upload" aria-hidden="true"></i></div>
            <div className="aButtonClassConfig" onClick={handleHome}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
        </div>

        <div style={{top: "0px", left: "000px", position: "relative", width: "280px", color:"#b3b3b3", padding: "15px"}}>
            PREVIEW
            <div id = {"Group_" + SchoolGroup.id} className={"card3 "}  style={{ backgroundColor: colorB, margin: "15px"}}>
                {/* <img className= "dcFlagLogo" src={"https://www.nebo.inupline.com/" + iconsContext(svgFiles[flag])} alt="dcFlagLogo"/> */}
                {flag && <img className= "dcFlagLogo" src={"https://www.nebo.inupline.com" + iconsContext(svgFiles[flag])} alt="dcFlagLogo"/>}

                {/* <img className= "dcimg" src={deck} alt="logo "/> */}
                <div className="dclbl01">CLASESS: 0</div>
                <div className="dclbl02">STUDENTS: {flag}</div>
                {/* <div className="dclbl03b" style={{ color: props.colort}} >{props.label}</div> */}
                <div className="dclbl04b" style={{ color: colorT}} >{title}</div>
                <div className="dclbl05b" style={{ color: colorT}} >{note}</div>
                {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> colort */}
            </div>
        </div>

        <div className="form_0">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="userid" readOnly={"user_id"} />
                
                        <label className="desc" id="title_l" htmlFor="title">
                            Назва предмету або групи:
                        </label>
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-font"></i></span>
                            <input id="title" name="title" type="text" placeholder="NAME" value={title} required onChange={handleNameChange}/>
                        </div>

                        <label className="desc" id="typeid_l" htmlFor="typeid">
                            Рівні складності:
                        </label>
                        <div className="input_field select_option">
                            <select id="typeid" value={level} onChange={handleLevelChange}>
                                <option value={""} key={0}>All Levels</option>
                                <option value={"1"} key={1}>Introductory Level</option>
                                <option value={"2"} key={2}>Basic Level</option>
                                <option value={"3"} key={3}>Intermediate Level</option>
                                <option value={"4"} key={4}>Advanced Level</option>
                                <option value={"5"} key={5}>Expert Level</option>
                            </select>
                            <div className="select_arrow"></div>
                        </div>

                        
                        <label className="desc" id="typeid_l" htmlFor="typeid">
                            Іконка або логотип:
                        </label>
                        <div className="input_field select_option">
                            <select value={flag} onChange={handleflagChange}>
                                {svgFiles.map((filename, index) => (
                                    <option key={index} value={index}>
                                        {filename}
                                    </option>
                                ))}
                            </select>
                            <div className="select_arrow"></div>
                        </div>

                        <label className="desc" id="typeid_l" htmlFor="typeid">
                            Короткий опис:
                        </label>
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-tag"></i></span>
                            <input id="note" name="note" type="text" placeholder="Idioma" value={note} onChange={handleLNGNameChange}/>
                        </div>

                        <div className="input_field" > 
                            <label className="desc" id="typeid_l" htmlFor="lurl">
                                Колір тексту 
                            </label>
                            <input id="lurl" name="lurl" type="color" colorformat="rgba" placeholder="Color" value={colorT} required onChange={handleColorTChange}/>
                            <label className="desc" id="typeid_l" htmlFor="lurl">
                                Колір фону 
                            </label>
                            <input id="lurl" name="lurl" type="color" placeholder="Color" value={colorB} required onChange={handleColorBChange}/>
                        </div>

                        {/* <div className="input_field"> 
                            Back color <input id="lurl" name="lurl" type="color" placeholder="Color" value={colorB} required onChange={handleColorBChange}/>
                        </div> */}

                        <input className="button" type="submit" value="UPDATE"/>
                    </form>


                    
              </div> </>
    );
}

const toggleClass3 = () => {
    const oldClassName = document.getElementById('Menu03').className;
    if (oldClassName === 'menu2open') {
      const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
      document.getElementById('Menu03').className=newClassName;
      var workPageNew = "body"
      const workPageOld = document.getElementById('body').className;
      if (workPageOld === 'bodylite' ) workPageNew = 'bodycenter'
      if (workPageOld === 'body' ) workPageNew = 'bodyright'
      if (workPageOld === 'bodyright' ) workPageNew = 'body'
      if (workPageOld === 'bodycenter' ) workPageNew = 'bodylite'
      document.getElementById('body').className=workPageNew;
    }
  }