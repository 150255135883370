import React , {  useState , useEffect} from 'react';
import '../style_school.css';
import ReactDOM, { createRoot  } from 'react-dom/client';
import UsersList from '../project_users';
// import QRcode from '../../components/QRcode/qrcode';
import QRCode from 'qrcode.react';

import logo from '../../img/logo_company.png';  //sadmin.png
import arrow_left from '../../img/arrow-narrow-left.png';  
import icon_plus from '../../img/icon_plus.png';
import Icon_help from '../../img/icon_help.png';

export const IconsColor = (icons_id, color, size) => {
    //   filter: "invert(100%) sepia(0%) saturate(7488%) hue-rotate(144deg) brightness(100%) contrast(100%)"
    let icons_png = null
  
    if (icons_id === 0 ) { icons_png = arrow_left}
    if (icons_id === 1 ) { icons_png = icon_plus}
    if (icons_id === 2 ) { icons_png = Icon_help}
    if (icons_id === 3 ) { icons_png = arrow_left}
    if (icons_id === 4 ) { icons_png = arrow_left}
  
    const arrowStyle = {
        margin: "7px",
        backgroundColor: `${color}`, // "var(--text_color_menu)"
        width: `${size}`, 
        height: `${size}`,
        WebkitMaskImage: `url(${icons_png})`, maskImage: `url(${icons_png})`, WebkitMaskRepeat: "no-repeat", maskRepeat: "no-repeat",WebkitMaskSize:"100%",maskSize:"100%"
    };
  
    return (
    <div style={arrowStyle}>
      {/* <img src={icons_png} alt="" style={arrowStyle} /> */}
    </div>
    );
  }


function AddForm() {
    // Estados para almacenar los valores de los campos
    const [email, setEmail] = useState('email@gmail.com');
    const [roleId, setRoleID] = useState(8);
    const [templateHTML, setTemplateHTML] = useState(0);
    const [note, setNote] = useState('note');
    const [groupid, setGroupID] = useState(0);
    const [dateSt, setDateSt] = useState('2024-02-22');

    const activeProjectId = localStorage.getItem('projectid');

    const [classGroup, setClassGroup] = useState([]);
    const [countclassGroup, setCountClassGroup] = useState(0);

    function getDate() {
        const separator='-';
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        setDateSt(`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`);
    }
    
    useEffect(() => { 
        getDate()
        loadClass()
    }, []); // AUTOLOAD

    const loadClass = () => {
        fetch('https://www.nebo.inupline.com/api/web/school/getGroups/'+ activeProjectId, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            // body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("API->postAddClass", response)
            setClassGroup(response.groups)
            setCountClassGroup(response.count)
          } else {
            console.log("ERROR postAddClass-" , response)
        }
      });
    };

    //const navigate = useNavigate();
    // const login_token = useSelector((state)=> state.counter.user.login_token);
    const login_token = localStorage.getItem('login_token');

     // Manejadores de cambios para actualizar los estados
     const handleNameChange = (e) => {
        setEmail(e.target.value);
    };

    const handleLevelChange = (e) => {
        setRoleID(e.target.value);
    };

    const handleHTMLChange = (e) => {
        setTemplateHTML(e.target.value);
    };
   

    const handleLNGNameChange = (e) => {
        setNote(e.target.value);
    };

    const handleGroupIDChange = (e) => {
        setGroupID(e.target.value);
        console.log ("e.target.value",e.target.value)
    };

    const handleDateStChange = (e) => {
        setDateSt(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes hacer algo con los valores, como enviarlos a un servidor
        // console.log('email:', email);
        // console.log('roleId:', roleId);
        // console.log('html:', templateHTML);
        // console.log('note:', note);
        // console.log('dateSt:', dateSt); 
        // console.log('login_token:', login_token);
        // console.log('projectid:', activeProjectId);
        // console.log('groupid:', groupid);

        const userData = {
            projectid: activeProjectId,
            email: email,
            roleId: roleId,
            html: templateHTML,
            note: note,
            dateSt: dateSt,
            groupid: groupid,
        };

        fetch('https://www.nebo.inupline.com/api/web/invite/add', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData)
        })

      .then(response => response.json())
      .then(response => {
            console.log("API->web/invite/add ", response)
          if (response.status===200) {
            console.log("API->api/invite/add", response)
            //navigate(`/projects`)
            // dispatch(application.setUpdateTime(response.update));
          } else {
            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
            console.log("ERROR api/invite/add-" , response)
        //setLoading(false)
        }
      });
    };

    return (
        <div className="form_0">
            <form onSubmit={handleSubmit}>
                {/* <input type="hidden" name="userid" readOnly={"user_id"} /> */}
                <label className="desc" id="title_l" htmlFor="title">
                    EMAIL кому надіслати запрошення:
                </label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="title" name="title" type="text" placeholder="NAME" value={email} required onChange={handleNameChange}/>
                </div>

                <label className="desc" id="typeid_l" htmlFor="typeid">
                    Роль кандидата:
                </label>
                <div className="input_field select_option">
                    <select id="typeid" value={roleId} onChange={handleLevelChange}>
                        <option value={"8"} key={0}>Student</option>
                        <option value={"7"} key={1}>Profesor</option>
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <label className="desc" id="typeid_l" htmlFor="typeid">
                    Шаблон запрошення:
                </label>
                <div className="input_field select_option">
                    <select id="typeid" value={templateHTML} onChange={handleHTMLChange}>
                        <option value={"0"} key={0}>Sin EMAIL</option>
                        <option value={"1"} key={1}>Standart UA</option>
                        <option value={"2"} key={2}>Standart ES</option>
                        <option value={"3"} key={3}>Standart ENG</option>
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <label className="desc" id="typeid_l" htmlFor="typeid">
                    Короткий опис:
                </label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-tag"></i></span>
                    <input id="note" name="note" type="text" placeholder="Idioma" value={note} onChange={handleLNGNameChange}/>
                </div>

                <label className="desc" id="typeid_l" htmlFor="typeid">
                    Пряме запрошення у клас ( Всього класів: {countclassGroup} ):
                </label>
                <div className="input_field select_option">
                    <select value={groupid} onChange={handleGroupIDChange}>
                            <option value={null}>
                                Весь проект (Запрошення для персоналу )
                            </option>
                        {classGroup.map((filename, index) => (
                            <option key={index} value={filename.groupid}>
                                {filename.cursname} - {filename.groupname} ({filename.cursnote} / {filename.groupid})
                            </option>
                        ))}
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <div className="input_field"> 
                    <label className="desc" htmlFor="start_date">Коли (дата) надіслати запрошення:</label>
                    <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sign-in"></i></span>
                        <input type="date" id="start_date" name="trip-start" value= {dateSt} min="2024-01-01" max="2030-12-31" onChange={handleDateStChange}/>
                    </div>
                </div>

                <input className="button" type="submit" value="SAVE"/>
            </form>
        </div> 
    );
}

function SchoolDecks() {
    const userList = localStorage.getItem('ProjectUserList');
    const activeProjectId = localStorage.getItem('projectid');

    console.log("userList", JSON.parse(userList))

    const handleHome = () => {
        // ReactDOM.render(<UsersList users={JSON.parse(userList)}/>, document.getElementById('UserMenuBoxBODY'));
        const tools = createRoot(document.getElementById('UserMenuBoxBODY'));
        tools.render(<UsersList users={JSON.parse(userList)}/>);
    };

    return (
        <div>
            <div>
                <div className="project_urls"  >
                    <div className="aButtonClassBack" onClick={handleHome}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>
                    <div className="cLabelClassHeaderBar">Invite user</div>
                    {/* <div className="aButtonClassAdd" onClick={handleHome}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassDelete" onClick={() => handleDelDeck(cid)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassImport" onClick={handleHome}><i className="fa fa-upload" aria-hidden="true"></i></div>
                    <div className="aButtonClassConfig" onClick={handleHome}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                </div>
                    {/* <DeckSteps /> */}
                    <AddForm/>
                    {/* <QRcode value="123123123" size={128}/> */}
                    <QRCode className="aLogo" value={"https://www.nebo.inupline.com/projects"} size={150} fgColor={"#000000"} bgColor={"#FFFFFF"} level={"H"} imageSettings={{src:"/img/miniU.png", height: 64, width: 64,excavate:true}}/>

            </div>
        </div>
    );
}

export default SchoolDecks;
