import React , { useRef, useState , useEffect} from 'react';
import { useNavigate, useParams} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
// import {Card} from "../components/cards/cards";
// import LoadingUI from '../components/loading/loadingui';
import Avatar from '../../img/avatar.jpeg';  //sadmin.png avatar.jpeg temp02.png
import tmpimg from '../../img/temp02.png';  //sadmin.png avatar.jpeg temp02.png
import star_anki from '../../img/star_anki.png';  //sadmin.png avatar.jpeg temp02.png
import flag_es from '../../img/ESCR2.png';  //sadmin.png avatar.jpeg temp02.png
import deck from '../../img/deck.png';  //sadmin.png avatar.jpeg temp02.png
import {useApiData, useLoadApiFoto, IconsColor} from "../../functions/useApiData"
// import ReactDOM from 'react-dom';
import ReactDOM, { createRoot  } from 'react-dom/client';

import UserAva from '../../img/user.png';  //noche.png
import NocheAva from '../../img/noche.png';  //noche.png
import InAva from '../../img/arrow-circle-broken-right.png';  //noche.png
import OutAva from '../../img/arrow-circle-broken-left.png';  //noche.png

import IconDELETE from '../../img/iconDEL.png';  
import IconEDIT from '../../img/iconEDIT.png';  

import IconADD from '../../img/iconADD.png';
import ImgF003 from '../../img/BFimg/F003.jpg';  //sadmin.png
import logo from '../../img/logo_company.png';  //sadmin.png

import '../userlist.css'; // Стилі для елемента

import { DeckForm , EditClass} from '../school_add_class' // MODULE SCHOOL

import '../style_school.css';
import './style_vut.css';





export function InviteList(props) {
    //WELCOM PAGE
    const [imgIco, setImgIco] = useState();
    //const dispatch = useDispatch();

    const openLinkURL = (url) => {
        props.selid (props.json.ID)
    }

    // const iconLink = () => {
    //     setImgIco(tmpimg)     
    // }

    // useEffect(() => {
    //     iconLink()
    // }, []); // AUTOLOAD

    return (
        <li className="lisinmarker" >
            <figure className="userlist"  onClick={openLinkURL} >
                <span className="linenumero">{props.index}</span>
                <span className="outdate"><i className="fa fa-share-square-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.CHECKIN}</span>
                <span className="acdate"><i className="fa fa-check-square-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.CHECKOUT}</span>

                <span className="status">
                    {/* {props.json.CLIENTE == 0 && <i className="fa fa-exclamation-circle" style={{color:"blue"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE == 1 && <i className="fa fa-paper-plane" style={{color:"grey"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>} */}
                    {props.json.CLIENTE == props.json.VIAJEROS && <i className="fa fa-check-circle" style={{color:"green"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE > props.json.VIAJEROS && <i className="fa fa-check-circle" style={{color:"red"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE < props.json.VIAJEROS && <i className="fa fa-check-circle" style={{color:"orange"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}

                    {/* {props.json.CLIENTE == 3 && <i className="fa fa-times-circle" style={{color:"red"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>}
                    {props.json.CLIENTE == 4 && <i className="fa fa-clock-o" style={{color:"orange"}}>{props.json.CLIENTE}/{props.json.VIAJEROS}</i>} */}
                </span> 

                <span className="email"> <i className="fa fa-envelope" style={{color:"green"}}></i>&nbsp;&nbsp;&nbsp;{props.json.NUMBER}</span>
            
            </figure>
        </li>
    );
}

export function StudentsCard(props) {
    
  const openLinkURL = (url) => {
      props.selid (props.ids)
  }

  const calculateNights = (checkIn, checkOut) => {
    // Перетворюємо строки в об'єкти Date
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);
  
    // Обчислюємо різницю в мілісекундах
    const timeDifference = checkOutDate - checkInDate;
  
    // Перетворюємо в кількість діб (одна доба = 24 години = 86400000 мілісекунд)
    const nights = timeDifference / (1000 * 3600 * 24);
  
    // Якщо різниця менша за 1 день, повертаємо 1 ніч (це залежить від вашої логіки)
    return nights < 1 ? 1 : Math.floor(nights);
  };

  return (

      <div id = {"Card_" + props.ids} className={"card_parte card-0"} onClick={openLinkURL} style={props.style}>
              <div class="booking-container">
                <div class="booking-text">TEST</div>
              </div>
              
              {/* <div className="cButton" onClick={() => ShowMenuApp("appid", props.id)} >...</div> */} 
              {/* <div className="cButtonFlip" onClick={(e) => flipCard(e.target.parentNode.parentNode.id)}><i className="fas fa-bolt">...</i></div> */}
              <div className="sTitle">{props.title}</div>
              <div className="sEmail">{props.email}</div>
              {/* <div className="task__levelUser task__levelCard--0" > DISABLED </div> */}

              <div className="task__levelUser task__levelCard--1" > OK </div>
              <img className= "sUser" src={UserAva} referrerPolicy="no-referrer" alt="logo "/>
              <img className= "sNoche" src={NocheAva} referrerPolicy="no-referrer" alt="logo "/>

              <img className= "sInICO" src={InAva} referrerPolicy="no-referrer" alt="logo "/>
              <img className= "sOutICO" src={OutAva} referrerPolicy="no-referrer" alt="logo "/>

              {/* {props.level == 0 && <div className="task__levelUser task__levelCard--0" > DISABLED </div> }
              {props.level == 1 && <div className="task__levelUser task__levelCard--1" > Lv. BASIC </div> }
              {props.level == 2 && <div className="task__levelUser task__levelCard--2" > Lv. MIDDLE </div> }
              {props.level == 3 && <div className="task__levelUser task__levelCard--3" > Lv. ADVANCED </div> }
              {props.level == 4 && <div className="task__levelUser task__levelCard--4" > Lv. NATIVE </div> } */}

              {/* <div className="sA1">{props.A1} X</div> // checkin.slice(0, 10);
              <div className="sA2">{props.A2} C </div>
              <div className="sA3">{props.A3} V</div> */}

              <div className="sId">ID: {props.ids}</div>
              <div className="sStatus">{props.clientes} / {props.viajeros}</div>
              <div className="sNoches"> {calculateNights(props.datein, props.dateout)}</div>

              <div className="sDateIn">{props.datein.slice(0, 10)}</div>  
              <div className="sDateOut">{props.dateout.slice(0, 10)}</div>

             

          {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> */}
      </div>
  );
}

export function LevelCard(props) {
    //WELCOM PAGE
    const [imgIco, setImgIco] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const openLinkURL = (url) => {
        props.selid (props.ids)
    }

    useEffect(() => {
        setImgIco(tmpimg)     

    }, []); // AUTOLOAD

    return (

        <div id = {"Card_" + props.ids} className={props.cs ? "card3 card-" + props.cs : "card3 card-3"} onClick={openLinkURL} style={props.style}>
                {/* <div className="cButton" onClick={() => ShowMenuApp("appid", props.id)} >...</div> */} 
                {/* <div className="cButtonFlip" onClick={(e) => flipCard(e.target.parentNode.parentNode.id)}><i className="fas fa-bolt">...</i></div> */}
                <img className= "lLogo" src={!props.imgs ? imgIco : "/img/" + props.imgs} alt="logo "/>
                <div className="llblTitle">lvl name:</div>
                <div className="lTitle">{props.title}</div>
                <div className="lNote">{props.note}</div>
            {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> */}
        </div>
    );
}

export function DecksCard(props) {
    //WELCOM PAGE
    const [imgIco, setImgIco] = useState();

    const openLinkURL = (id) => {
        props.selid (id)
    }

    useEffect(() => {
        setImgIco(tmpimg)     
    }, []); // AUTOLOAD

    return (

        <div id = {"Decks_" + props.id} className={props.cs ? "card_deck card-" + props.cs : "card3 card-3"} onClick={() => openLinkURL(props.id)} style={props.style}>
                <img className= "dcLogo" src={star_anki} alt="dcLogo"/>
                {/* <img className= "dcimg" src={deck} alt="logo "/> */}
                <div className="dclbl01">ID: {props.id}</div>
                <div className="dclbl02">CARDS: {props.cards}</div>
                <div className="dclbl03" style={{ color: props.colort}} >{props.label}</div>
                <div className="dclbl04" style={{ color: props.colort}} >{props.title}</div>
                <div className="dclbl05" style={{ color: props.colort}} >{props.note}</div>
            {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> colort */}
        </div>
    );
}

export function GroupCard(props) {
    //WELCOM PAGE

    const iconsContext = require.context('../../img/flags/', false, /\.svg$/);
    const svgFiles = iconsContext.keys();

    const [imgIco, setImgIco] = useState();

    const openLinkURL = (id) => {
        props.selid (id)
    }

    const upDate = (id) => {
        props.update (id)
    }

    const ShowMenu = (page, ids) => {
        const oldClassName = document.getElementById('Menu03').className;
        if (oldClassName === 'menu2hide' ) {
            const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
            document.getElementById('Menu03').className=newClassName;
            var workPageNew = "body"
            const workPageOld = document.getElementById('body').className;
            if (workPageOld === 'bodylite' ) {workPageNew = 'bodycenter'}
            if (workPageOld === 'body' ) {workPageNew = 'bodyright'}
            if (workPageOld === 'bodyright' ) {workPageNew = 'body'}
            if (workPageOld === 'bodycenter' ) {workPageNew = 'bodylite'}
            document.getElementById('body').className=workPageNew;
        }

        const oldMenuUser = document.getElementById('Menu02').className;
        const closeUserMenu = oldMenuUser === 'menu2open' ? 'menu2hide' : 'menu2hide'
        document.getElementById('Menu02').className=closeUserMenu;
    }

    const ToolsOpenClass = (ids) => {
        ShowMenu()
        const tools = createRoot(document.getElementById('UserMenuBoxBODY'));
        tools.render(<EditClass class_id = {ids} update = {upDate}/>);
    }

    useEffect(() => {
        setImgIco(tmpimg)     
    }, []); // AUTOLOAD

    return (

        <div id = {"Group_" + props.component.id} className={"card3"} style={{ backgroundColor: props.component.colorb}}>
            <div id = {"e_" + props.component.id} style={{position: "absolute", width:"100%",height:"100%", left:"0px", top:"0px"}} onClick={() => openLinkURL(props.component.id)} >  
                <img className= "dcFlagLogo" src={iconsContext(svgFiles[!props.component.icoAva ? 236 : props.component.icoAva])} alt="dcFlagLogo"/>
                {/* <img className= "dcimg" src={deck} alt="logo "/> */}
                <div className="dclbl01">ID: {props.component.id}</div>
                <div className="dclbl02">Students: {props.component.students}</div>
                {/* <div className="dclbl03b" style={{ color: props.component.colort}} >{props.component.label}</div> */}
                <div className="dclbl04b" style={{ color: props.component.colort}} >{props.component.title}</div>
                <div className="dclbl05b" style={{ color: props.component.colort}} >{props.component.note}</div>
            </div>  
                <div className="actions">
                  {/* <img className ="edit-btn" src={IconDELETE} alt={item.cursname} onClick={() => onDoubleClick(item)}/> */}
                  <img className ="edit-btn" style={{position: "absolute" ,right:"0px"}} src={IconEDIT} alt={"edit"} onClick={() => ToolsOpenClass(props.component.id)}/>

                  {/* <img className ="edit-btn" src={IconDELETE} alt={item.groupname} onClick={() => onDoubleClick(item)}/> */}
                </div>
            {/* <p className="card__date"><i className="fas fa-times">{props.date}</i></p> colort */}
        </div>
    );
}


const CalendarWithNavigation = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);

  // Функція для отримання даних з API
  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const response = await fetch("https://www.nebo.inupline.com/api/web/vut/postUrlCalendar", {
          method: "POST",
          headers: {
            Authorization: "555888666999",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            urls: [
              "https://www.airbnb.es/calendar/ical/1164486650741867310.ics?s=a333fb2e4e360c34d98d2ea2ecf869d5",
              "https://ical.booking.com/v1/export?t=114441cb-bb81-4e1c-8695-6dfd6136ab9c",
            ],
          }),
        });

        const result = await response.json();
        if (result.status === 200) {
          setEvents(result.data);
          console.log("Calendar data:", result.data);
        } else {
          console.error("Error fetching calendar data:", result);
        }
      } catch (error) {
        console.error("Error fetching calendar data:", error);
      }
    };

    fetchCalendarData();
  }, []);

  // Функція для отримання масиву місяців для відображення
  const getMonthsToDisplay = () => {
    const months = [];
    for (let i = -1; i <= 1; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i);
      months.push({
        year: date.getFullYear(),
        month: date.getMonth(),
      });
    }
    return months;
  };

  // Функції навігації
  const handleNextMonths = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 3));
  };

  const handlePreviousMonths = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 3));
  };

  const handleCurrentMonth = () => {
    setCurrentDate(new Date());
  };

  // Перевірка зайнятості дати
  const isDateOccupied = (date) => {
    // Форматуємо дату в формат YYYYMMDD
    const formattedDate = date.toISOString().split("T")[0].replace(/-/g, "");
    
    return events.some(({ datein, dateout }) => {
      // Приводимо дати початку і кінця до формату YYYYMMDD
      const startDate = datein;  // Це вже формат YYYYMMDD
      const endDate = dateout;   // Це вже формат YYYYMMDD
  
      // Порівнюємо відформатовану дату з діапазоном
      return formattedDate >= startDate && formattedDate <= endDate;
    });
  };

  // Функція для отримання кольору для дати
const getDateColor = (date) => {
  const formattedDate = date.toISOString().split("T")[0].replace(/-/g, "");
  for (const { datein, dateout, platform } of events) {
    const startDate = datein;  // формат YYYYMMDD
    const endDate = dateout;   // формат YYYYMMDD
    if (formattedDate >= startDate && formattedDate <= endDate) {
      return platform === "airbnb" ? "red" : "blue"; // Визначаємо колір для кожної платформи
    }
  }
  return null; // Якщо не знайдено, повертаємо null (прозорий фон)
};

// Функція для отримання стилю для дати
const getDateStyle = (date) => {
  const formattedDate = date.toISOString().split("T")[0].replace(/-/g, "");
  let style = {};

  // Проходимо по подіях і перевіряємо чи співпадає дата з datein або dateout
  for (const { datein, dateout, platform } of events) {
    const startDate = datein;  // формат YYYYMMDD
    const endDate = dateout;   // формат YYYYMMDD

    // Якщо дата є в діапазоні між datein і dateout, додаємо заливку
    if (formattedDate > startDate && formattedDate < endDate) {
      //style.borderBottom= "3px solid " + (platform === "airbnb" ? "red" : "blue");
      style.backgroundColor = platform === "airbnb" ? "rgba(255, 0, 0, 0.2)" : "rgba(0, 0, 255, 0.2)";
    }

    // Якщо дата дорівнює datein, додаємо правий бордер
    if (formattedDate === startDate) {
      //style.borderRight = "3px solid " + (platform === "airbnb" ? "red" : "blue");
      style.background = platform === "airbnb" ? "linear-gradient(135deg,#fff 45%,#fff 45%,#fff 55%, rgba(255, 0, 0, 0.2) 55%" : 
      "linear-gradient(135deg,#fff 45%,#fff 45%,#fff 55%, rgba(0, 0, 255, 0.2) 55%";
      style.zIndex = 0
    }

    // Якщо дата дорівнює dateout, додаємо лівий бордер
    if (formattedDate === endDate) {
      //style.borderLeft = "3px solid " + (platform === "airbnb" ? "red" : "blue");
      //style.backgroundColor = "#fff";
      style.background = platform === "airbnb" ? "linear-gradient(135deg, rgba(255, 0, 0, 0.2) 25%,#fff 25%,#fff 55%, #fff 55%" : 
      "linear-gradient(135deg,rgba(0, 0, 255, 0.2) 25%,#fff 25%,#fff 55%, #fff 55%";
      style.zIndex = 0
    }

    // Якщо дата одночасно є і для datein, і для dateout, додаємо обидва бордери
    if (formattedDate === startDate && formattedDate === endDate) {
      //style.borderLeft = "3px solid " + (platform === "airbnb" ? "red" : "blue");
      //style.borderRight = "3px solid " + (platform === "airbnb" ? "red" : "blue");
      //style.backgroundColor = platform === "airbnb" ? "rgba(255, 0, 0, 0.2)" : "rgba(0, 0, 255, 0.2)";
      // style.background = platform === "airbnb" ? "linear-gradient(135deg, rgba(255, 0, 0, 0.2) 25%,#fff 25%,#fff 55%, #fff 55%" : 
      // "linear-gradient(135deg,rgba(0, 0, 255, 0.2) 25%,#fff 25%,#fff 55%, #fff 55%";
      // style.background = platform === "airbnb" ? "linear-gradient(135deg,#fff 45%,#fff 45%,#fff 55%, rgba(255, 0, 0, 0.2) 55%" : 
      // "linear-gradient(135deg,#fff 45%,#fff 45%,#fff 55%, rgba(0, 0, 255, 0.2) 55%";
    }
  }

  return style;
};

/// Рендер одного місяця
const renderMonth = ({ year, month }) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDay = new Date(year, month, 1).getDay();

  return (
    <div key={`${year}-${month}`} className="month">
      <h3>{new Date(year, month).toLocaleString("default", { month: "long", year: "numeric" })}</h3>
      <div className="calendar-grid">
        {Array.from({ length: firstDay }, (_, i) => (
          <div key={`empty-${i}`} className="empty"></div>
        ))}
        {Array.from({ length: daysInMonth }, (_, day) => {
          const date = new Date(year, month, day + 2);
          const style = getDateStyle(date); // Отримуємо стиль для дати

          return (
            <div
              key={date}
              className="day"
              style={style}
              title={style.borderLeft || style.borderRight ? "Occupied" : "Available"}
            >
              {day + 1}
            </div>
          );
        })}
      </div>
    </div>
  );
};

  return (
    <div className="calendar-container">
      <div className="navigation">
        <button onClick={handlePreviousMonths}>-3 Meses</button>
        <button onClick={handleNextMonths}>+3 Meses</button>
        <button onClick={handleCurrentMonth}>Mes Actual</button>
      </div>
      <div className="months">
        {getMonthsToDisplay().map(renderMonth)}
      </div>
    </div>
  );
};

  

function School(props) {
    const [SchoolArray, setSchoolArray] = useState([]); // IMPORT Group / Course
    const [SchoolClass, setSchoolClass] = useState([]); // IMPORT Class
    const [SchoolDecks, setDecks] = useState([]); // IMPORT DECKS
    const [SchoolStudents, setStudents] = useState([]); // IMPORT DECKS

    const [ProjectURL, setProjectURL] = useState([]); // IMPORT URL

    const apiEndpointAPI2 = `/project/url/${props.pid}`;

    const { data: UrlData, loading: loading2, error: APIError2 } =  useApiData('GET', apiEndpointAPI2);

    const [selectedID, setSelectedID] = useState(null);

    //const login_token = useSelector((state)=> state.counter.user.login_token);
    const login_token = localStorage.getItem('login_token');

    const navigate = useNavigate();

    const [userRole, setUserRole] = useState(0);

    const [inviteList, setInviteList] = useState([]); // IMPORT Group / Course

    const apiEndpointInvite = `/vut/getPartesViajerosList/${props.pid}`;
    const { data: JSONInviteList, loading: loadingInvite, error: ErrorInvite } =  useApiData('GET', apiEndpointInvite);

    useEffect(() => { 
        if (JSONInviteList ) {
            setInviteList(JSONInviteList.PARTE
            )
            console.log ("JSONInviteList", JSONInviteList.PARTE
            )
        }
    }, [JSONInviteList]); // AUTOLOAD


    useEffect(() => {
        if (UrlData.URLs && UrlData.URLs.length > 0) {
            setProjectURL(UrlData.URLs)
            //console.log("UrlData",UrlData)
        } else {
            //console.warn("ListData.projects es undefined o vacío");
        }
    }, [props.pid,UrlData, selectedID]); // AUTOLOAD

    const updateall = () => {
    fetch('https://www.nebo.inupline.com/api/web/school/getCours/' + props.pid, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + login_token,
        },
    })
    .then(response => response.json())
    .then(response => {
        if (response.status===200) {
            //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
            setSchoolArray(response.levels)
            setStudents(response.students)
            // setLoading(false)
            console.log("/getCours-" , response)
        } else {
            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
            console.warn("ERROR getCours-" , response)
            setSchoolArray([])
            setSchoolClass([])
            setDecks([])
        //setLoading(false)
        }
    });}


        useEffect(() => {
            updateall()
        }, [props.pid]); // AUTOLOAD

            useEffect(() => {
                fetch('https://www.nebo.inupline.com/api/web/school/getDecks/' + props.pid, {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + login_token,
                    },
                })
                .then(response => response.json())
                .then(response => {
                    if (response.status===200) {
                        //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
                        setDecks(response.decks)
                        // setLoading(false)
                        console.log("/getDecks-" , response)
                    } else {
                        // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
                        console.log("ERROR getDecks-" , response)
                    //setLoading(false)
                    }
                }); }, [props.pid]); // AUTOLOAD


            useEffect(() => {
                //console.log(selectedID)
                // navigate("/firstrun");

                if(selectedID) {
                    fetch('https://www.nebo.inupline.com/api/web/school/getClass/' + selectedID, {
                        method: 'GET',
                        headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + login_token,
                        },
                    })
                    .then(response => response.json())
                    .then(response => {
                        if (response.status===200) {
                            //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
                            setSchoolClass(response.groups)
                            // setLoading(false)
                            console.log("/getClass-" , response)
                        } else {
                            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
                            console.log("ERROR getClass-" , response)
                            setSchoolClass([])
    
                        //setLoading(false)
                        }
    
                    });
                }
                 }, [selectedID]); // AUTOLOAD


        const activeProjectId = localStorage.getItem('projectid');

        const handlePartedValue = (childValue) => {
            localStorage.setItem('parteid', childValue)
            navigate(`/parteinvite`);
        };

        const handleStudentID = (childValue) => {
            // setSelectedID(childValue);
            // open class page
            //const class_id = localStorage.getItem('classid');
            localStorage.setItem('studentid', childValue)

            navigate(`/students`);
        };


        const handleShowDecks = (childValue) => {
            // setSelectedID(childValue);
            // open class page
            localStorage.setItem('deckid', childValue)
            navigate(`/decks`);
        };

        const handleShowAddDeck = (childValue) => {
            navigate(`/projects/adddeck`);
        };

        const handleShowAddClass = (childValue) => {
            //navigate(`/projects/addclass`);

            // ReactDOM.render(<DeckForm pid = {activeProjectId}/>, document.getElementById('UserMenuBoxBODY'));
            const tools = createRoot(document.getElementById('UserMenuBoxBODY'));
            tools.render(<DeckForm pid = {activeProjectId}/>);
            
            ShowToolsMenu()
        };

        const ShowToolsMenu = () => {
            const oldClassName = document.getElementById('Menu03').className;
            if (oldClassName === 'menu2hide' ) {
                const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
                document.getElementById('Menu03').className=newClassName;
                var workPageNew = "body"
                const workPageOld = document.getElementById('body').className;
                if (workPageOld === 'bodylite' ) {workPageNew = 'bodycenter'}
                if (workPageOld === 'body' ) {workPageNew = 'bodyright'}
                if (workPageOld === 'bodyright' ) {workPageNew = 'body'}
                if (workPageOld === 'bodycenter' ) {workPageNew = 'bodylite'}
                document.getElementById('body').className=workPageNew;
            }
    
            const oldMenuUser = document.getElementById('Menu02').className;
            const closeUserMenu = oldMenuUser === 'menu2open' ? 'menu2hide' : 'menu2hide'
            document.getElementById('Menu02').className=closeUserMenu;
        }

        const handleToolsInvite = (childValue) => {
            console.log("vchat",childValue)
            navigate(`/vchat`);

        };

        const handleAddInvite = (childValue) => {
            localStorage.setItem('parteid', "NEW")
            navigate(`/parteinvite`);
        };

        const handleGoHome = (childValue) => {
            navigate(`/`);
        };

        

        return (
            <div>
                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}>  { IconsColor(3, "var(--text_color_menu)","24px") }</div>

                        <div className="cLabelClassHeaderBar">CALENDARIO</div>
                        <div className="aButtonClassImport" onClick={() => handleShowAddClass()}>{ IconsColor(1, "var(--text_color_menu)","24px") }</div>
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassConfig">{ IconsColor(2, "var(--text_color_menu)","24px") }</div>
                    </div>

                    <div className="classDeckslist" >
                        <CalendarWithNavigation />
                    </div>
                </div>
                 
                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassHome"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>

                        <div className="cLabelClassHeaderBar">CLIENTES</div>
                        <div className="aButtonClassConfig" onClick={() => handleShowAddDeck()}>{ IconsColor(1, "var(--text_color_menu)","24px") }</div>
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                    </div>

                    <div class="containerx">
  <div class="buttonx">12</div>
  <div class="buttonx">12</div>
  <div class="buttonx">12</div>
</div>

                </div>

                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>

                        <div className="cLabelClassHeaderBar">RESERVAS</div>
                        {/* <div className="aButtonClassAdd"><i className="fa fa-plus" aria-hidden="true"></i></div>
                        <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                        <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div>
                        <div className="aButtonClassConfig"><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                    </div>

                    <div className="classgroup" >
                            {inviteList && inviteList.map((itemg, index) => ( 
                                <StudentsCard 
                                    key = {index} // OK
                                    ids={itemg.ID} // OK
                                    title={itemg.REFCONTRATO} // OK - G1 ! => GRUPGROUP.GRUPNAME
                                    label = {itemg.label} // Label ? ! => GRUPGROUP.GRUPLABEL
                                    photo={itemg.photo} // OK  ! => GRUPGROUP.GRUPICON
                                    A1 = {itemg.A1} // Text color ! => GRUPGROUP.GRUPCOLORT
                                    A2 = {itemg.A2} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    A3 = {itemg.A3} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    email = {itemg.email} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    clientes = {itemg.CLIENTE} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    viajeros = {itemg.VIAJEROS} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    datein = {itemg.CHECKIN} // Fon color ! => GRUPGROUP.GRUPCOLORB
                                    dateout = {itemg.CHECKOUT} // Fon color ! => GRUPGROUP.GRUPCOLORB

                                    selid={handlePartedValue} // Click Function
                                />
                            ))} 
                    </div>    
                </div>

                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>

                        <div className="cLabelClassHeaderBar">PARTES DE VIAJEROS(XML MASIVA) </div>
                        <div className="aButtonClassImport" onClick={() => handleAddInvite()}>{ IconsColor(1, "var(--text_color_menu)","24px") }</div>
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassConfig">{ IconsColor(2, "var(--text_color_menu)","24px") }</div>
                    </div>

                    <ul className="grid_invite_user">
                            {inviteList && inviteList.map((itemg, index) => ( 
                                <InviteList 
                                    key = {index} 
                                    index = {index+1}
                                    json={itemg} 
                                    selid={handlePartedValue} // Click Function
                                />
                            ))} 
                    </ul>    
                </div>

                <div>
                    <div className="project_urls"  >
                        {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassBack" onClick={() => handleGoHome()}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>

                        <div className="cLabelClassHeaderBar">Sobre vivenda</div>
                        <div className="aButtonClassImport" onClick={() => handleAddInvite()}>{ IconsColor(1, "var(--text_color_menu)","24px") }</div>
                        {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div> */}
                        {/* <div className="aButtonClassImport"><i className="fa fa-upload" aria-hidden="true"></i></div> */}
                        <div className="aButtonClassConfig">{ IconsColor(2, "var(--text_color_menu)","24px") }</div>
                    </div>

                    {/* <div className="classlevellist" >
                        {ProjectURL.map((itemg, index) => ( 
                            <LevelCard style={{ backgroundColor: itemg.colorb,  color: itemg.colort }} 
                            key = {index} 
                            ids={itemg.id} 
                            title={itemg.title} 
                            date = {itemg.title}  
                            cs={itemg.title} 
                            note = {itemg.label} 
                            imgs={itemg.ico} 
                            selid={handleChildValue}/>
                        ))}
                </div> */}
            </div>
        </div>
    );
}

export default School;
