import React , {  useState , useEffect} from 'react';
import { useNavigate, useParams} from "react-router-dom";  
import '../style_school.css';
import {useApiData,IconsColor} from "../../functions/useApiData"

function DeckForm(props) {
    const navigate = useNavigate();

    const [viajeroValor1, setViajeroValor1] = useState('VI');                   // Rol
    const [viajeroValor2, setViajeroValor2] = useState('');                     // Nombre
    const [viajeroValor3, setViajeroValor3] = useState('');                     // Apellido 1
    const [viajeroValor4, setViajeroValor4] = useState('');                     // Apellido 2
    const [viajeroValor5, setViajeroValor5] = useState('');                     // Tipo Documento
    const [viajeroValor6, setViajeroValor6] = useState('');                    // Número Documento
    const [viajeroValor7, setViajeroValor7] = useState('');                    // Soporte Documento
    const [viajeroValor8, setViajeroValor8] = useState('');                    // Fecha Nacimiento
    // PAGO
    const [viajeroValor9, setViajeroValor9] = useState('');                     // Nacionalidad
    const [viajeroValor10, setViajeroValor10] = useState('');                   // Sexo
    const [viajeroValor11, setViajeroValor11] = useState('');                   // Dirección
    const [viajeroValor12, setViajeroValor12] = useState('');                   // Complementaria
    const [viajeroValor13, setViajeroValor13] = useState('');                   // Código Municipi
    const [viajeroValor14, setViajeroValor14] = useState('');                   // Nombre Municipio
    const [viajeroValor15, setViajeroValor15] = useState('');                   // Código Postal
    const [viajeroValor16, setViajeroValor16] = useState('');                   // País
    const [viajeroValor17, setViajeroValor17] = useState('');                   // Teléfono
    const [viajeroValor18, setViajeroValor18] = useState('');                   // Teléfono 2
    const [viajeroValor19, setViajeroValor19] = useState('');                   // Correo
    const [viajeroValor20, setViajeroValor20] = useState('');                   // Parentesco
    const [viajeroValor21, setViajeroValor21] = useState('');                   // Parentesco

    const activeParteId = localStorage.getItem('parteid');
    const activeViajeroId = localStorage.getItem('viajeroid');
    const login_token = localStorage.getItem('login_token');
    const language = localStorage.getItem('language');



    useEffect(() => {
          // перевірити валідність токену
          if (activeViajeroId != "NEW"){

            fetch('https://www.nebo.inupline.com/api/web/vut/getViajero/' + activeViajeroId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + login_token,
                },
            })
                .then(response => response.json())
                .then(response => {
                    
                    if (response.status===200) {
                        console.log("type OK activeViajeroId-" , response.VIAJERO[0])
                        setViajeroValor1(response.VIAJERO[0].ROLE);
                        setViajeroValor2(response.VIAJERO[0].NAME);
                        setViajeroValor3(response.VIAJERO[0].APEDIDO);
                        setViajeroValor4(response.VIAJERO[0].APEDIDO2);
                        setViajeroValor5(response.VIAJERO[0].DOCUMENTO);
                        setViajeroValor6(response.VIAJERO[0].NUMDOCUMENTO);
                        setViajeroValor7(response.VIAJERO[0].NUMSOPORTE);
                        setViajeroValor8(response.VIAJERO[0].DATABIRDAY); //
                        setViajeroValor9(response.VIAJERO[0].NATIONALIDAD);
                        setViajeroValor10(response.VIAJERO[0].SEXO);
                        setViajeroValor11(response.VIAJERO[0].DIRECTION1);
                        setViajeroValor12(response.VIAJERO[0].DIRECTION2);
                        setViajeroValor13(response.VIAJERO[0].DIRCODE);
                        setViajeroValor14(response.VIAJERO[0].DIRMUNICIPIO);
                        setViajeroValor15(response.VIAJERO[0].ZIP);
                        setViajeroValor16(response.VIAJERO[0].PAIS);
                        setViajeroValor17(response.VIAJERO[0].TELEFONO);
                        setViajeroValor18(response.VIAJERO[0].TELEFONO2);
                        setViajeroValor19(response.VIAJERO[0].EMAIL);
                        setViajeroValor20(response.VIAJERO[0].PARENTESCO);
                        setViajeroValor21(response.VIAJERO[0].STATUS);
                    } else if (response.status===409) {
                        console.log("type KO-activeViajeroId:" , response)
                    }
                });  
            }
        }, [activeViajeroId]); // AUTOLOAD
    
    const [documentsList, setDocumentsList] = useState([]); // DOCUMENTS TYPE
    const [paisList, setPaisList] = useState([]); // PAIS TYPE
    const [sexoList, setSexoList] = useState([]); // SEXO TYPE
    const [ParantescoList, setParantescoList] = useState([]); // SEXO TYPE

    const apiEndpointInvite = `/vut/getValores`;
    const { data: JSONInviteList, loading: loadingInvite, error: ErrorInvite } =  useApiData('GET', apiEndpointInvite);

    useEffect(() => { 
        if (JSONInviteList.DOCUMENTS ) {
                setDocumentsList(JSONInviteList.DOCUMENTS)
                setPaisList(JSONInviteList.PAISES)
                setSexoList(JSONInviteList.SEXO)
                setParantescoList(JSONInviteList.PARANTESCO)
                console.log ("getValores", JSONInviteList)
        }
    }, [JSONInviteList]); // AUTOLOAD

     // Manejadores de cambios para actualizar los estados
    const handleValorChange1 = (e) => {
        setViajeroValor1(e.target.value);
    };

    const handleValorChange2 = (e) => {
        setViajeroValor2(e.target.value);
    };

    const handleValorChange3 = (e) => {
        setViajeroValor3(e.target.value);
    };

    const handleValorChange4 = (e) => {
        setViajeroValor4(e.target.value);
    };

    const handleValorChange5 = (e) => {
        setViajeroValor5(e.target.value);
    };

    const handleValorChange6 = (e) => {
        setViajeroValor6(e.target.value);
    };

    const handleValorChange7 = (e) => {
        setViajeroValor7(e.target.value);
    };

    const handleValorChange8 = (e) => {
        setViajeroValor8(e.target.value);
    };

    const handleValorChange9 = (e) => {
        setViajeroValor9(e.target.value);
    };

    const handleValorChange10 = (e) => {
        setViajeroValor10(e.target.value);
    };

    const handleValorChange11 = (e) => {
        setViajeroValor11(e.target.value);
    };

    const handleValorChange12 = (e) => {
        setViajeroValor12(e.target.value);
    };

    const handleValorChange13 = (e) => {
        setViajeroValor13(e.target.value);
    };

    const handleValorChange14 = (e) => {
        setViajeroValor14(e.target.value);
    };

    const handleValorChange15 = (e) => {
        setViajeroValor15(e.target.value);
    };

    const handleValorChange16 = (e) => {
        setViajeroValor16(e.target.value);
    };

    const handleValorChange17 = (e) => {
        setViajeroValor17(e.target.value);
    };

    const handleValorChange18 = (e) => {
        setViajeroValor18(e.target.value);
    };

    const handleValorChange19 = (e) => {
        setViajeroValor19(e.target.value);
    };

    const handleValorChange20 = (e) => {
        setViajeroValor20(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const userData = {
            //VUTC_ID: "110",
            VUTC_PARTEID: activeParteId,
            VUTC_ROLE: viajeroValor1,
            VUTC_NAME: viajeroValor2,
            VUTC_APEDIDO: viajeroValor3,
            VUTC_APEDIDO2: viajeroValor4,
            VUTC_DOCUMENTO: viajeroValor5,
            VUTC_NUMDOCUMENTO: viajeroValor6,
            VUTC_NUMSOPORTE: viajeroValor7,
            VUTC_DATABIRDAY: viajeroValor8,
            VUTC_NATIONALIDAD: viajeroValor9,
            VUTC_SEXO: viajeroValor10,
            VUTC_DIRECTION1: viajeroValor11,
            VUTC_DIRECTION2: viajeroValor12,
            VUTC_DIRCODE: viajeroValor13,
            VUTC_DIRMUNICIPIO: viajeroValor14,
            VUTC_ZIP: viajeroValor15,
            VUTC_PAIS: viajeroValor16,
            VUTC_TELEFONO: viajeroValor17,
            VUTC_TELEFONO2: viajeroValor18,
            VUTC_EMAIL: viajeroValor19,
            VUTC_PARENTESCO: viajeroValor20,
            VUTC_ROCESSID: "1"
        };

        fetch(`https://www.nebo.inupline.com/api/web/vut/postAddPersona${activeViajeroId !== "NEW" ? '/' + activeViajeroId : ''}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData)
        })

      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("postAddParte", response)
            navigate(-1);
            // dispatch(application.setUpdateTime(response.update));
          } else {
            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
            console.log("postAddParte" , response)
            navigate(-1);
        }
      });
    };

    return (
        <div className="form_0">
            <form onSubmit={handleSubmit}>
                {/* <input type="hidden" name="userid" readOnly={"user_id"} /> */}
                {/* <label className="desc" htmlFor="val1">
                Rol:
                </label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val1" name="val1" type="text" placeholder="VI" value={viajeroValor1} onChange={handleValorChange1} required disabled/>
                </div> */}

                <label className="desc" htmlFor="val2">First name (Nombre):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val2" name="val2" type="text" placeholder="Nombre" value={viajeroValor2} onChange={handleValorChange2} required/>
                </div>

                <label className="desc" htmlFor="val3">First surname (Apellido 1):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val3" name="val3" type="text" placeholder="Apellido 1" value={viajeroValor3} onChange={handleValorChange3} required/>
                </div>

                <label className="desc" htmlFor="val4">Second surname (Apellido 2):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val4" name="val4" type="text" placeholder="Apellido 2" value={viajeroValor4} onChange={handleValorChange4}/>
                </div>
                
                <label className="desc" id="typeid_l" htmlFor="val5">Document type (Tipo Documento):</label>
                <div className="input_field select_option">
                    <select id="val5" name="val5" value={viajeroValor5} onChange={handleValorChange5}>
                            {/* <option value={null}>
                                Весь проект (Запрошення для персоналу )
                            </option> */}
                        {documentsList.map((filename, index) => (
                            <option key={index} value={filename.code}>
                                {language === "es" ? filename.esp : language === "en" ? filename.eng : filename.ukr}
                            </option>
                        ))}
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <label className="desc" htmlFor="val6">Document number (Número Documento):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val6" name="val6" type="text" placeholder="Número Documento" value={viajeroValor6} onChange={handleValorChange6} required/>
                </div>

                <label className="desc" htmlFor="val7">Soporte Documento (Document support) (sólo para Españoles /only for Spanish) :</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val7" name="val7" type="text" placeholder="Soporte Documento" value={viajeroValor7} onChange={handleValorChange7}/>
                </div>

                <div className="input_field"> 
                    <label className="desc" htmlFor="val8">Date of birth (Fecha Nacimiento):</label>
                    <div className="input_field"> <span><i aria-hidden="true" className="fa fa-sign-in"></i></span>
                        <input type="date" id="val8" name="val8" value= {viajeroValor8} onChange={handleValorChange8} required/>
                    </div>
                </div>

                <label className="desc" id="typeid_l" htmlFor="val9">Nationality (Nacionalidad):</label>
                <div className="input_field select_option">
                    <select id="val9" name="val9" value={viajeroValor9} onChange={handleValorChange9}>
                            {/* <option value={null}>
                                Весь проект (Запрошення для персоналу )
                            </option> */}
                        {paisList.map((filename, index) => (
                            <option key={index} value={filename.code}>
                                {language === "es" ? filename.esp : language === "en" ? filename.eng : filename.ukr}
                            </option>
                        ))}
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <label className="desc" id="typeid_l" htmlFor="val10">Gender (Sexo):</label>
                <div className="input_field select_option">
                    <select id="val10" name="val10" value={viajeroValor10} onChange={handleValorChange10}>
                            {/* <option value={null}>
                                Весь проект (Запрошення для персоналу )
                            </option> */}
                        {sexoList.map((filename, index) => (
                            <option key={index} value={filename.code}>
                                {language === "es" ? filename.esp : language === "en" ? filename.eng : filename.ukr}
                            </option>
                        ))}
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <br></br><b>Address / Dirección</b> <br></br>

                <label className="desc" htmlFor="val11">Address (Dirección):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val11" name="val11" type="text" placeholder="Dirección" value={viajeroValor11} onChange={handleValorChange11} required/>
                </div>

                <label className="desc" htmlFor="val12">Additional address (Complementaria):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val12" name="val12" type="text" placeholder="Complementaria" value={viajeroValor12} onChange={handleValorChange12} required/>
                </div>

                <label className="desc" htmlFor="val13">Province (Código Municipio):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val13" name="val13" type="text" placeholder="Código Municipio" value={viajeroValor13} onChange={handleValorChange13} required/>
                </div>

                <label className="desc" htmlFor="val14">Municipality (Nombre Municipi):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val14" name="val14" type="text" placeholder="Nombre Municipi" value={viajeroValor14} onChange={handleValorChange14} required/>
                </div>

                <label className="desc" htmlFor="val15">Postal code (Código Postal):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val15" name="val15" type="text" placeholder="Código Postal" value={viajeroValor15} onChange={handleValorChange15} required/>
                </div>

                <label className="desc" id="typeid_l" htmlFor="val16">Country (País):</label>
                <div className="input_field select_option">
                    <select id="val16" name="val16" value={viajeroValor16} onChange={handleValorChange16}>
                            {/* <option value={null}>
                                Весь проект (Запрошення для персоналу )
                            </option> */}
                        {paisList.map((filename, index) => (
                            <option key={index} value={filename.code}>
                                {language === "es" ? filename.esp : language === "en" ? filename.eng : filename.ukr}
                            </option>
                        ))}
                    </select>
                    <div className="select_arrow"></div>
                </div>

                <label className="desc" htmlFor="val17">Phone number (Teléfono):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val17" name="val17" type="text" placeholder="Teléfono" value={viajeroValor17} onChange={handleValorChange17} required/>
                </div>

                <label className="desc" htmlFor="val18">Additional phone number (Teléfono 2):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val18" name="val18" type="text" placeholder="Teléfono 2" value={viajeroValor18} onChange={handleValorChange18}/>
                </div>

                <label className="desc" htmlFor="val19">Email address (Correo):</label>
                <div className="input_field"> <span><i aria-hidden="true" className="fa fa-envelope-o"></i></span>
                    <input id="val19" name="val19" type="text" placeholder="Teléfono" value={viajeroValor19} onChange={handleValorChange19} required/>
                </div>

                <label className="desc" id="typeid_l" htmlFor="val20">Relationship (indicate if under 14 years of age) (Parentesco indicar si es menor de 14 años):</label>
                <div className="input_field select_option">
                    <select id="val20" name="val20" value={viajeroValor20} onChange={handleValorChange20}>
                            {/* <option value={null}>
                                Весь проект (Запрошення для персоналу )
                            </option> */}
                        {ParantescoList.map((filename, index) => (
                            <option key={index} value={filename.code}>
                                {language === "es" ? filename.esp : language === "en" ? filename.eng : filename.ukr}
                            </option>
                        ))}
                    </select>
                    <div className="select_arrow"></div>
                </div>

                

                <input style={{marginTop: "15px"}} className="button" type="submit" value={activeViajeroId !== "NEW" ? 'UPDATE'  : 'SAVE'}/>
            </form>

        </div> 
    );
}

function SchoolDecks(props) {
    //const { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id
    const navigate = useNavigate();

    const activeProjectId = localStorage.getItem('projectid');
    const activeViajeroId = localStorage.getItem('viajeroid');

    const handleHome = (childValue) => {
        navigate(-1);
    };

    return (
        <div>
            <div>
                <div className="project_urls"  >
                    <div className="aButtonClassBack" onClick={handleHome}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>
                    <div className="cLabelClassHeaderBar">Add Traveler / Anadir Viajero {activeViajeroId}</div>
                    {/* <div className="aButtonClassAdd" onClick={handleHome}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassDelete" onClick={() => handleDelDeck(cid)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                    <div className="aButtonClassImport" onClick={handleHome}><i className="fa fa-upload" aria-hidden="true"></i></div>
                    <div className="aButtonClassConfig" onClick={handleHome}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
                </div>
                    {/* <DeckSteps /> */}
                    <DeckForm pid = {activeProjectId} />
                    {/* <MDEditor.Markdown className='PreviewPageInvite' source={htmlContent} /> */}
            </div>
        </div>
    );
}

export default SchoolDecks;
