import React, { useState, useEffect } from 'react';
import { GoogleOAuthProvider, GoogleLogin  } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { useDispatch } from 'react-redux';
import { actUser } from '../../redux/store';
import { useNavigate, useSearchParams } from "react-router-dom";  

import LoadingUI from '../../components/loading/loadingui';
import logo from '../../img/best_logo_blk.png';  //sadmin.png
import { GetDeviceID } from "../../firebase"

function SsoApp(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const join_code = searchParams.get("code"); // "text"

  const [showCode, setShowCode] = useState(false);

  const [nerror, setError] = useState('Introduzca su pin code');

  const [nname, setNname] = useState('nikc_NAME');
  const [ncode, setNcode] = useState('P8C1U33');
  const [npin, setNpin] = useState('123456');


  useEffect(() => {
    setLoading(false)
    allStorage()
    const usremail = localStorage.getItem('usremail');
    if (usremail) {dispatch(actUser.setUserEmail(usremail));}
  }, []);

  useEffect(() => {
    setNcode(join_code)
    console.error('join_code:', join_code);
  }, []);

  const handleSearchTermChange = (usr) => {
      GetDeviceID()
      .then(token => {
        // Ваша логіка з використанням отриманого токену
        localStorage.setItem('TokenDevice', token)
      })
      .catch(error => {
        console.error('Помилка TokenDevice:', error);
        // Обробка помилки
      });

    const TokenDevice = localStorage.getItem('TokenDevice');

      setLoading(true)
      
      localStorage.setItem('usremail', usr.email)
      localStorage.setItem('usrpicture', usr.picture)
      localStorage.setItem('usrfamily_name', usr.family_name)
      localStorage.setItem('usrgiven_name', usr.given_name)
      localStorage.setItem('usrname', usr.name)

      const userData = {
        email: usr.email,
        name: usr.name,
        picture: usr.picture,
        token_device: TokenDevice
        //birthday: "1900-01-01"
      };

      fetch('https://www.nebo.inupline.com/api/app/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'inLine 234234fe23423ewfedf244132@3aws23',
          },
          body: JSON.stringify(userData),
      })
          .then(response => response.json())
          .then(response => {
              if (response.status=200) {
                console.log("handleSearchTermChange api/login firs run", response)
                localStorage.setItem('login_token', response.login_token)
                localStorage.setItem('userid', response.perfil.user_id)
                localStorage.setItem('projectid', response.perfil.project_id)

                dispatch(actUser.setUserEmail(response.perfil.user_mail));
                
                  if (response.perfil.ts_accept_v === null || response.perfil.ts_accept_v === 0) {
                        navigate("/firstrun");
                    } else {
                        //console.log("/-" , userData)
                        navigate("/");
                    }  
              } else {
              }
          });  
        setLoading(false)
  };


  const handleJoinQR = () => {
    GetDeviceID()
    .then(token => {
      // Ваша логіка з використанням отриманого токену
      localStorage.setItem('TokenDevice', token)
    })
    .catch(error => {
      console.error('Помилка TokenDevice:', error);
      // Обробка помилки
    });

  const TokenDevice = localStorage.getItem('TokenDevice');

    setLoading(true)
    
    const userData = {
      code: join_code,
      nickname: nname,
      pin: npin,
      token_device: TokenDevice
      //birthday: "1900-01-01"
    };

    fetch('https://www.nebo.inupline.com/api/web/loginqr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'inLine 234234fe23423ewfedf244132@3aws23',
        },
        body: JSON.stringify(userData),
    })
        .then(response => response.json())
        .then(response => {
            if (response.status===200) {
              console.log("handleSearchTermChange api/login firs run", response)
              localStorage.setItem('login_token', response.login_token)
              localStorage.setItem('userid', response.perfil.user_id)
              localStorage.setItem('projectid', response.perfil.project_id)

              localStorage.setItem('usremail', nname)
              // localStorage.setItem('usrfamily_name', usr.family_name)
              // localStorage.setItem('usrgiven_name', usr.given_name)
              localStorage.setItem('usrname', nname)

              // navigate("/firstrun");
              dispatch(actUser.setUserEmail(nname));
              
                if (response.perfil.ts_accept_v === null || response.perfil.ts_accept_v === 0) {
                      navigate("/firstrun");
                      // navigate("/firstrun");

                  } else {
                      //console.log("/-" , userData)
                      navigate("/");
                  }  
            } else if (response.status===401) {
              setError("Error PIN CODE");      
            }
        });  
      setLoading(false)
};

  function allStorage() {

    var archive = [],
        keys = Object.keys(localStorage),
        i = 0, key;

    for (; key = keys[i]; i++) {
        archive.push( key + '=' + localStorage.getItem(key));
    }

    console.log( "localStorage", archive);
}

  // function OutME() {
  //   localStorage.setItem('usremail', "")
  //   localStorage.setItem('usrpicture', "")
  //   localStorage.setItem('usrfamily_name', "")
  //   localStorage.setItem('usrgiven_name', "")
  //   localStorage.setItem('usrname', "")
  //   localStorage.setItem('userid', "")
  //   localStorage.setItem('login_token', "")
  // }

  const handleNameChange = (e) => {
    setNname(e.target.value);
  };

  const handlePinChange = (e) => {
    setNpin(e.target.value);
  };

  const handleCodeChange = (e) => {
    setNcode(e.target.value);
  };

  const showJoinQR = () => {
    setShowCode(true)
    console.log(true)
  }

  if (loading){
    return (<LoadingUI tcapt = "Loading"></LoadingUI>)
} else {
  return (
     <GoogleOAuthProvider clientId="993790840435-muhfa9cjp6i88q79fabvav6fd2dn305b.apps.googleusercontent.com">

     <div className="AppWrapper">
        <header className="header">
          <img className= "AppLoginLogo" src={logo} alt="logo "/>
        </header>
        <div className='AppWelcom'>WELCOM</div>

      {!props.code ?  <> <GoogleLogin
          onSuccess={credentialResponse => {
            var decoded = jwt_decode(credentialResponse.credential);
            // console.log(credentialResponse.credential);
            console.log(decoded);
            // setUser(decoded);
            handleSearchTermChange(decoded);
          }}
          onError={() => {
            console.log('Login Failed');
          }}
          useOneTap = {false }
          type = "standard"
          theme = "filled_blue"
          size = "large"
          width = "300"
      />
            {showCode===false && <div className='loginQRbtn' onClick={() => showJoinQR()}>CODE</div>}

      </>: 
      <>
      {/* <div className='addnotetext' style={{top:"550px", width:"60%"}}> 
        {join_code}
      </div> */}

      <label className="labelNickName" id="title_l" htmlFor="nname">
          NEW NickName:
      </label>
      <div className="input_field">
          <input className='NickNameInput' id="nname" name="nname" type="text" placeholder="NICKNAME" value={nname} required onChange={handleNameChange}/>
      </div>

      <label className="labelNickName" id="title_l" htmlFor="npin">
          NEW PIN:
      </label>
      <div className="input_field">
          <input className='NickNameInput' id="npin" name="npin" type="text" placeholder="NICKNAME" value={npin} required onChange={handlePinChange}/>
      </div>

      <label className="labelNickName" id="title_l" htmlFor="ncode">
          CODE JOIN:
      </label>
      <div className="input_field">
          <input className='NickNameInput' id="ncode" name="ncode" type="text" placeholder="NICKNAME" value={ncode} required onChange={handleCodeChange}/>
      </div>

      <div className='loginQRbtn' onClick={() => handleJoinQR()}>JOIN</div>

      </>}

      {showCode===true &&  <>
        <label className="labelNickName" id="title_l" htmlFor="nname">
          NickName:
      </label>
      <div className="input_field">
          <input className='NickNameInput' id="nname" name="nname" type="text" placeholder="NICKNAME" value={nname} required onChange={handleNameChange}/>
      </div>

      <label className="labelNickName" id="title_l" htmlFor="npin">
          PIN:
      </label>
      <div className="input_field">
          <input className='NickNameInput' id="npin" name="npin" type="text" placeholder="NICKNAME" value={npin} required onChange={handlePinChange}/>
      </div>

      <div className='loginQRbtn' onClick={() => handleJoinQR()}>JOIN</div>

      <div className="labelNickName">{nerror}</div>
      </> }



    <footer className="AppFooter">DEMO V4</footer>
  </div>          
    </GoogleOAuthProvider>    
  );
}
}

export default SsoApp;
