import React from "react";
import {useEffect, useState } from "react";
import UserAva from './../img/guest_nano.png';  //sadmin.png avatar.jpeg
import Company from './WEB_SCHOOL/school_add_users' // MODULE COMPANY
import ReactDOM, { createRoot  } from 'react-dom/client';
import {IconsColor} from "../functions/useApiData"

function UsersList(props) {

    const handleAddUsers = () => {
        // ReactDOM.render(<Company/>, document.getElementById('UserMenuBoxBODY'));
        const tools = createRoot(document.getElementById('UserMenuBoxBODY'));
        tools.render(<Company/>);
    }

    const handleChildValue = (childValue) => {
        // localStorage.setItem('classid', childValue)
        console.log("handleChildValue", childValue)
    };

    const toggleClass3 = () => {
        const oldClassName = document.getElementById('Menu03').className;
        if (oldClassName === 'menu2open') {
          const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
          document.getElementById('Menu03').className=newClassName;
          var workPageNew = "body"
          const workPageOld = document.getElementById('body').className;
          if (workPageOld === 'bodylite' ) workPageNew = 'bodycenter'
          if (workPageOld === 'body' ) workPageNew = 'bodyright'
          if (workPageOld === 'bodyright' ) workPageNew = 'body'
          if (workPageOld === 'bodycenter' ) workPageNew = 'bodylite'
          document.getElementById('body').className=workPageNew;
        }
      }


    return (
    <div>
        <div className="project_urls"  >
            {/* <div className="aButtonClassBack"><i className="fa fa-chevron-left" aria-hidden="true"></i></div> */}
            <div className="aButtonClassBack"  onClick={toggleClass3}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>

            <div className="cLabelClassHeaderBar">Users</div>
            {/* <div className="aButtonClassImport"><i className="fa fa-plus" aria-hidden="true"></i></div> */}
            {/* <div className="aButtonClassDelete"><i className="fa fa-trash-o" aria-hidden="true"></i></div> */}
            <div className="aButtonClassImport" onClick={handleAddUsers}>{ IconsColor(1, "var(--text_color_menu)","24px") }</div>
            <div className="aButtonClassConfig">{ IconsColor(2, "var(--text_color_menu)","24px") }</div>
        </div>

        <div className="user_filter_role">
            <div className="frole_students">STUDENTS</div>
            <div className="frole_teacher">TEACHER</div>
            <div className="frole_otros">OTROS</div>
        </div>

        <div className="grid_project_users">
                {props.users && props.users.map((itemg, index) => ( 
                    <InviteList 
                        key = {index} 
                        index = {index+1}
                        json={itemg} 
                        selid={handleChildValue} // Click Function
                    />
                ))} 
        </div>    
    </div>
    );
}

export default UsersList;

export function InviteList(props) {
    //WELCOM PAGE
    const [imgIco, setImgIco] = useState();
    //const dispatch = useDispatch();

    const openLinkURL = (url) => {
        props.selid (props.ids)
    }

    return (
        <>
        <figure className={props.json.user_role == 8 ? "userslist users_students" : props.json.user_role == 5 || props.json.user_role == 4 ? "userslist users_teacher" : "userslist users_users"} >
            <img className= "sLogo" src={!props.json.user_avatar ? UserAva : props.json.user_avatar} referrerPolicy="no-referrer" alt="logo "/>
            <span className="linenumero">{props.index}</span>

            {/* <span className="outdate"><i className="fa fa-share-square-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.datecreate}</span>
            <span className="acdate"><i className="fa fa-check-square-o" ></i>&nbsp;&nbsp;&nbsp;{props.json.dateupdate}</span> */}

            {/* <span className="status">
                {props.json.user_role == 6 && <i className="fa fa-exclamation-circle" style={{color:"blue"}}></i>}
                {props.json.user_role == 7 && <i className="fa fa-paper-plane" style={{color:"grey"}}></i>}
                {props.json.user_role == 8 && <i className="fa fa-check-circle" style={{color:"green"}}></i>}
                {props.json.user_role == 3 && <i className="fa fa-times-circle" style={{color:"red"}}></i>}
                {props.json.user_role == 4 && <i className="fa fa-clock-o" style={{color:"orange"}}></i>}
            </span> */}
            <span className="name"> &nbsp;&nbsp;&nbsp;{props.json.user_name}</span>
            <span className="email"> &nbsp;&nbsp;&nbsp;{props.json.user_mail}</span>
            {/* {props.json.roleid !=7 & props.json.roleid !=8 ? <span className="email"> <i className="fa fa-envelope" style={{color:"green"}}></i>&nbsp;&nbsp;&nbsp;{props.json.user_name}</span>:null} */}
            {/* {props.json.roleid == 7 && <span className="email"> <i className="fa fa-envelope" style={{color:"orange"}}></i>&nbsp;&nbsp;&nbsp;{props.json.user_name}</span>} */}
            {/* {props.json.roleid == 8 && <span className="email"> <i className="fa fa-envelope" style={{color:"grey"}}></i>&nbsp;&nbsp;&nbsp;{props.json.user_name}</span>} */}
        </figure>
        </>
    );
}