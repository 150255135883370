import React , { useState } from 'react';
import {IconsColor} from "../../functions/useApiData"

function SideMenu(props) {

  const toggleClass3 = () => {
    const oldClassName = document.getElementById('Menu03').className;
    if (oldClassName === 'menu2open') {
      const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
      document.getElementById('Menu03').className=newClassName;
      var workPageNew = "body"
      const workPageOld = document.getElementById('body').className;
      if (workPageOld === 'bodylite' ) workPageNew = 'bodycenter'
      if (workPageOld === 'body' ) workPageNew = 'bodyright'
      if (workPageOld === 'bodyright' ) workPageNew = 'body'
      if (workPageOld === 'bodycenter' ) workPageNew = 'bodylite'
      document.getElementById('body').className=workPageNew;
    }
  }

    return (
      <div className="menu2hide" id="Menu03">
        <div id="UserMenuBoxBODY" className = 'UserMenuBox3'>

        <div className="project_urls"  >
                <div className="aButtonClassBack" onClick={toggleClass3}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>
                <div className="cLabelClassHeaderBar">{props.title_tool}</div>
                {/* <div className="aButtonClassAdd" onClick={handleHome}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
                <div className="aButtonClassDelete" onClick={() => handleDelDeck(cid)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                <div className="aButtonClassImport" onClick={handleHome}><i className="fa fa-upload" aria-hidden="true"></i></div>
                <div className="aButtonClassConfig" onClick={handleHome}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
        </div>

          {/* <iframe className = 'iframeapp' src="https://dev.guudjob.com/sso?jwt=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZGVudGlmaWVyIjoicHJ1ZWJhc3BsayJ9.EqK_sgx0FyOVro3-_pjt2KzcRs8dhlwAeJsqv7CXVQkK-xnDDHR5qe-yfcqs6OcsA_wf8yWuXFUg_a-wvR7_70S5vl4RQjfhZrrAAUW3eaeXegj49Vu4r0IoQKS423swl_tQZ2BI_og3JNgRV2A94NylHaniZdgjIECCkRSEKfBitqJ7NChru9CMMzk7nGjV2ni6hXA-5wDsQDImsBJL5KItKLnBBjXGlLr3900WUMeHR5OvK2PjS04Jl2zuejMwy2DnuDpQcBaCZQJfWjUnkJwx4P-9_Vbby7xVtFRXg9GgH_mVRyIFHMSqUM-KtFm4pdVMuj8meFXbjf41RJyXy2yACtfBRHDtp9vsHmvZOWZiqp5WqInU6ym78bi2eW-6NDVwhGkzrfX4tufkLUAEOByutoxw7oMQp5XI7u51RwBYYW6FYYXwMwxyVXpZxFAzd0HCyqtfIuk0yAFt_bu9OEBsiSY6Q8bhqEIqPkLkJxQ9B9Ldz1IwaK0VluSRkhdoi_ahLUxdm0sRgJKtZwqcNb3zvhv0aEr-HMmXEkQFpuRzQHhPv-VhMRhEuMdAWP28TrrARP6dPztM5ZXd-VdhRVNzB9r7gYSZKcbsDV_XvGCFod0HJTFuyFuhjlU81qrccKoAxERnaX6dbVvtMp5c31V7MlIe77MpyNnKq_uXLsE"></iframe> */}
          {props.children}
          {/* {props.PageInfo ? <AppInfo UnitInfo = {props.PageInfo} QRcol1 = {QRfondo}  QRcol2 = {QRcolor} /> : null} */}
        </div>
      </div>
    )
};
  
export default SideMenu;