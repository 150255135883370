import React , { useState , useEffect} from 'react';
import ReactDOM, { createRoot  } from 'react-dom/client';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams} from "react-router-dom";  
import LoadingUI from '../components/loading/loadingui';
import JSON_HomePage from '../json/json_home' // IMPORT ELEMENTS for HOME PAGE
import { actions, application } from '../redux/store';
import ImgF003 from './../img/BFimg/F003.jpg';  //sadmin.png
import logo from './../img/logo_company.png';  //sadmin.png

import School from './school.jsx' // MODULE SCHOOL
import Company from './WEB_COMPANY/index.jsx' // MODULE COMPANY
import VUT from './WEB_VUT/index.jsx' // MODULE COMPANY
import ProjectInfo from "./ProjectInfo.jsx"
import UserAva from './../img/guest_nano.png';  //sadmin.png avatar.jpeg
import iconSetings from './../img/iconSETINGS.png';  //sadmin.png avatar.jpeg
import iconUsers from './../img/iconUSERS.png';  //sadmin.png avatar.jpeg
import iconUser from './../img/iconUSER.png';  //sadmin.png avatar.jpeg

// import School_Student_Classes from './school_student_classes.jsx';

// school_student_classes
import {useApiData, useLoadApiFoto} from "../functions/useApiData"
import UsersList from './project_users';

function ProjectHome() {
    const activeProjectId = localStorage.getItem('projectid');
    const login_token = localStorage.getItem('login_token');
    const updatetime = useSelector((state)=> state.application.app.update); //Update time

    //const dispatch = useDispatch();

    const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + activeProjectId + '/logo.png');
    const [defaultFondo, setDefaultFondo] = useState("/img/projects/" + activeProjectId + '/fondo.png');

    const [project, setProject] = useState("loading ...");
    const [userRole, setUserRole] = useState(0);
    const [userAll, setUserAll] = useState(0);
    const [userList, setUserListl] = useState([]);


    //const navigate = useNavigate();
    const apiEndpointFondo = `/img/projects/${activeProjectId}/fondo.jpeg?cache=${updatetime}`;
    const apiEndpointLogo = `/img/projects/${activeProjectId}/logo.png?cache=${updatetime}`;
    const apiEndpointAPI1 = `/project/list/${activeProjectId}`;
    //const apiEndpointAPI2 = `/api/project/url/${pid}`;

    const { data: ListData, loading: loading, error: APIError1 } =  useApiData('GET', apiEndpointAPI1);
    //const { data: UrlData, loading: loading2, error: APIError2 } =  useApiData('GET', apiEndpointAPI2);

    const { data: LogoData, loading: Logoloading, error: LogoError } =  useLoadApiFoto('GET', apiEndpointLogo);
    const { data: FondoData, loading: FondoLoading, error: FondoError } = useLoadApiFoto('GET', apiEndpointFondo);

    const loadUsersProject = () => {
        const userData = {
            projectid: activeProjectId
        };

        fetch('https://www.nebo.inupline.com/api/web/login/getuserprojects', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("API->postAddClass", response)
            setUserAll(response.count)
            setUserListl(response.users)
            localStorage.setItem('ProjectUserList', JSON.stringify(response.users) )
            //navigate(`/projects`)
            // dispatch(application.setUpdateTime(response.update));
          } else {
            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
            console.log("ERROR postAddClass-" , response)
        //setLoading(false)
        }
      });
    };

    const loadDataProject = () => {
        // const userData = {
        //     projectid: activeProjectId
        // };

        fetch(`https://www.nebo.inupline.com/api/web/project/list/${activeProjectId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            // body: JSON.stringify(userData),
        })
      .then(response => response.json())
      .then(response => {
          if (response.status===200) {
            console.log("API->postAddClass", response)
            setProject(response.projects[0])

            // setUserAll(response.count)
            // setUserListl(response.users)
            // localStorage.setItem('ProjectUserList', JSON.stringify(response.users) )
            //navigate(`/projects`)
            // dispatch(application.setUpdateTime(response.update));
          } else {
            // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
            console.log("ERROR postAddClass-" , response)
        //setLoading(false)
        }
      });
    };

    useEffect(() => { 
        setDefaultFondo(FondoData)
    }, [FondoData,updatetime]);

    useEffect(() => { 
        setSelectedPhoto(LogoData)
    }, [LogoData,updatetime]); 

    useEffect(() => {
        const menu03Element = document.getElementById('Menu03');
        
        if (menu03Element) {
            const oldClassName = menu03Element.className;
        
            if (oldClassName === 'menu2open') {
                document.getElementById('Menu03').className='menu2hide';
                var workPageNew = "body"
                const workPageOld = document.getElementById('body').className;
                if (workPageOld === 'bodylite' ) {workPageNew = 'bodycenter'}
                if (workPageOld === 'body' ) {workPageNew = 'bodyright'}
                if (workPageOld === 'bodyright' ) {workPageNew = 'body'}
                if (workPageOld === 'bodycenter' ) {workPageNew = 'bodylite'}
                document.getElementById('body').className=workPageNew;
            }
        }
        
        if (ListData.projects && ListData.projects.length > 0) {
            setProject(ListData.projects[0])
            setUserRole(ListData.projects[0].userrole)
            // console.log("ListData ", ListData)
        } else {
            // Manejar el caso en el que ListData.projects es undefined o vacío
            console.warn("ListData.projects es undefined o vacío");
        }

    }, [activeProjectId, ListData, updatetime]); // AUTOLOAD

    useEffect(() => {
        loadUsersProject()
        //dispatch(application.setActivePage(HomePage));
    }, []);

    const ShowMenu = (page, ids) => {
        const oldClassName = document.getElementById('Menu03').className;
        if (oldClassName === 'menu2hide' ) {
            const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
            document.getElementById('Menu03').className=newClassName;
            var workPageNew = "body"
            const workPageOld = document.getElementById('body').className;
            if (workPageOld === 'bodylite' ) {workPageNew = 'bodycenter'}
            if (workPageOld === 'body' ) {workPageNew = 'bodyright'}
            if (workPageOld === 'bodyright' ) {workPageNew = 'body'}
            if (workPageOld === 'bodycenter' ) {workPageNew = 'bodylite'}
            document.getElementById('body').className=workPageNew;
        }

        const oldMenuUser = document.getElementById('Menu02').className;
        const closeUserMenu = oldMenuUser === 'menu2open' ? 'menu2hide' : 'menu2hide'
        document.getElementById('Menu02').className=closeUserMenu;
    }

    const ShowMenuApp = (page, ids) => {
        ShowMenu()
        // ReactDOM.render(<ProjectInfo />, document.getElementById('UserMenuBoxBODY'));
        const tools = createRoot(document.getElementById('UserMenuBoxBODY'));
        tools.render(<ProjectInfo update = {loadDataProject}/>);
    }

    const ShowUsersList = (page, ids) => {
        ShowMenu()
        // ReactDOM.render(<UsersList users={userList}/>, document.getElementById('UserMenuBoxBODY'));
        const tools = createRoot(document.getElementById('UserMenuBoxBODY'));
        tools.render(<UsersList users={userList} update = {loadUsersProject}/>);
    }

    if (loading){
        return (<LoadingUI tcapt = "Loading"></LoadingUI>)
    } else {
        return (
        <div className="container" style={{padding:"0px"}} >
            
            <div className="project_header" style={{padding:`0px`, backgroundSize:`100%`,  background:`url(${!defaultFondo ? (ImgF003) : (defaultFondo) }) center no-repeat`}} >

            <div className='transbox'> 
                {/* {userRole == 8 ? null : <div className="aButtonSetings" ><i className="fa fa-cog" aria-hidden="true"></i></div>} */}
                {userRole == 8 ? null : <img className="icButtonSetings" src={iconSetings} alt="logo" onClick={() => ShowMenuApp("company", project.project_id)}/>}
                <div className="projectlogo">
                    {!selectedPhoto ? ( <img className="plogo" src={logo} alt="logo" />):(
                    <div className="plogo" 
                        style={{width: "100px", height: "100px", backgroundImage:  `url(${selectedPhoto})`, backgroundSize: "100px 100px"}} >
                    {/* <div className="plogo" 
                    style={{width: "100px", height: "100px", backgroundColor: "rgb(230, 230, 230)", WebkitMaskImage: `url(${selectedPhoto})`, maskImage: `url(${selectedPhoto})`, WebkitMaskRepeat: "no-repeat", maskRepeat: "no-repeat",WebkitMaskSize:"100%",maskSize:"100%"  }} >
                    </div> */}
                    </div>
                        )}
                    </div>

                <div className="label">
                 {project.type_name} / {project.company_name}
                </div>

                <div className="name"  >
                    {project.project_name}
                </div>

                <div className="notes"  >
                    {project.project_note} 
                </div>
            
                <div className="company">
                company
                </div>
                
                <div className="iuButtonUsers" onClick={() => ShowUsersList("company", project.project_id)}>
                    <img className="itButtonUsers" src={iconUsers} alt="logo" /> 
                    <div className="ittButtonUsers">{userAll} </div>
                </div>
                
                <div className="type"  >
                type           
                </div>

                <div className="loadlogo"  >
                    load img            
                </div>

                <div className="loadimg"  >
                    load logo             
                </div>

                <div className="thema"  >
                    load thema             
                </div>

                <div className="AutorBox" >
                    <img className= "sLogo" src={!project.userico ? iconUser : project.userico } referrerPolicy="no-referrer" alt="logo "/>
                    <div className='arole'>autor:</div>
                    <span className="uname"> &nbsp;&nbsp;&nbsp;{project.username}</span>
                </div>

                {!project.im_ico ? null : <div className="UserBox" >
                    <img className= "sLogo" src={!project.im_ico ? iconUser : project.im_ico } referrerPolicy="no-referrer" alt="logo "/>
                    <div className='arole'>{project.im_rolename}</div>
                    <span className="uname"> &nbsp;&nbsp;&nbsp;{project.im_name}</span>
                </div> }

                </div>
            </div>

            {/* <div className="project_urls"  >
                <div className="cLabel">Nivel</div>
                <div className="cButton" onClick={() => ShowMenuApp("appid","x")}>...</div>
            </div>
            {ProjectURL ? <Cards homepage = {ProjectURL} appid= {props.InfoPage}/> : <div>LOAD</div> }

            <div className="project_urls"  >
                <div className="cLabel">Los URLs que está asignado en este proyecto:</div>
                <div className="cButton" onClick={() => ShowMenuApp("appid","x")}>...</div>
            </div> */} 
            {/* <LoadingUI tcapt = "Loading"></LoadingUI> */}
            
            {userRole == 8 ? 
                <div>
                    {/* <School_Student_Classes pid={activeProjectId} cid = {project.classes[0].class_id}/>  */}
                    {/* <School_Student_Classes pid={pid} cid={14}/>  */}

                    {/* Вчити зараз */}
                </div> : 
            <div> 
                {                 
                    project.type_id === "6" ? //SCHOOL
                    <div>
                        <School pid={activeProjectId}/>
                    </div> : null
                }

                {                 
                    project.type_id === "7" ? // COMPANY
                    <div> 
                        <Company pid={activeProjectId}/>
                    </div> : null
                }

{                 
                    project.type_id === "9" ? // COMPANY
                    <div> 
                        <VUT pid={activeProjectId}/>
                    </div> : null
                }
            </div>}
        </div>
        );
    }
}

export default ProjectHome;
