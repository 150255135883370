import React , { useRef, useState , useEffect} from 'react';
import { useNavigate, useParams} from "react-router-dom";  
import { useSelector, useDispatch } from 'react-redux';
import './style_school.css';
// import {Card} from "../components/cards/cards";
// import LoadingUI from '../components/loading/loadingui';
import ImgF000 from './../img/F000.png';  //fondo por defecto
import {IconsColor} from "../functions/useApiData"

import Avatar from './../img/avatar.jpeg';  //sadmin.png avatar.jpeg temp02.png
import tmplogo from './../img/temp01.png';  //sadmin.png avatar.jpeg temp02.png
import star_anki from './../img/star_anki.png';  //sadmin.png avatar.jpeg temp02.png

function DeckForm(props) {
    // Estados para almacenar los valores de los campos
    const [name, setName] = useState('A2+');
    const [level, setLevel] = useState('A2: Elemental');
    const [lngname, setLngname] = useState('Spanish');
    const [note, setNote] = useState('note text');
    const [misTypes, setTypes] = useState([]);
    const [misCompany, setCompany] = useState([]);
    const [misProjects, setProject] = useState([]);
    const [selType, setSelTypes] = useState(misProjects.type_id);
    const [selCompany, setSelCompany] = useState(misProjects.company_id);
    const [colorLogo, setColorLogo] = useState('#ffffff'); // Valor inicial del color en negro
    const [colorT, setColorT] = useState('#d3dbd5'); // Valor inicial del color en negro
    const [colorB, setColorB] = useState('#32785b'); // Valor inicial del color en negro
    const [alphaT, setAlphaT] = useState('0.1'); // Valor inicial del color en negro
    const [alphaB, setlphaB] = useState('0.1'); // Valor inicial del color en negro
    const navigate = useNavigate();

    const [selLogo, setLogo] = useState(null);
    const [selFondo, setFondo] = useState(null);

    const login_token = useSelector((state)=> state.counter.user.login_token);


    useEffect(() => {
        // перевірити валідність токену
  
        fetch('https://www.nebo.inupline.com/api/web/project/addtype', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
        })
            .then(response => response.json())
            .then(response => {
                
                if (response.status===200) {
                  //console.log("type OK-" , response.types)
                  setTypes(response.types)
  
                } else if (response.status===409) {
                  console.log("type KO-" , response)
                //   OutME()
                //   setLoading(false)
                }
            });  
      }, []); // AUTOLOAD
  
      useEffect(() => {
          // перевірити валідність токену
          
              fetch('https://www.nebo.inupline.com/api/web/company/list', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + login_token,
              },
          })
              .then(response => response.json())
              .then(response => {
                  
                  if (response.status===200) {
                    //console.log("company OK-" , response.company)
                    setCompany(response.company)
  
                  } else if (response.status===409) {
                    console.log("company KO-" , response)
                  //   OutME()
                  //   setLoading(false)
                  }
              });
      }, []); // AUTOLOAD

     // Manejadores de cambios para actualizar los estados
     const handleNameChange = (e) => {
        setName(e.target.value);
        props.pname (e.target.value)
    };

    const handleNoteChange = (e) => {
        setNote(e.target.value);
        props.pnote (e.target.value)
    };

    const handleColorLogoChange = (e) => {
        setColorLogo(e.target.value);
        props.colorLogo (e.target.value)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes hacer algo con los valores, como enviarlos a un servidor

        const userData = {
            name: e.target.title.value,
            note: e.target.note.value,
            userid: e.target.userid.value,
            typeid: e.target.typeid.value,
            code: e.target.code.value,
            logo: selLogo,
            fondo: selFondo,
          };

        fetch('https://www.nebo.inupline.com/api/web/project/list/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
            body: JSON.stringify(userData),
        })
        .then(response => response.json())
        .then(response => {
            if (response.status===200) {
                console.log("APP->setProjects", response)
                // dispatch(application.setUpdateTime(response.update));
            }
        });
    };

      function rgbaToHex(rgbaColor) {
        const match = rgbaColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/);
      
        if (!match) {
        //   throw new Error('Invalid RGBA color format');
        }
      
        const [, r, g, b, a] = match;
      
        // Конвертувати значення кожного каналу в шістнадцятковий формат
        const hexR = (+r).toString(16).padStart(2, '0');
        const hexG = (+g).toString(16).padStart(2, '0');
        const hexB = (+b).toString(16).padStart(2, '0');
        const hexA = Math.round(+a * 255).toString(16).padStart(2, '0');
      
        // Об'єднати значення кольору та альфа-каналу
        // const hexColor = `#${hexR}${hexG}${hexB}${hexA}`;
        const hexColor = `#${hexR}${hexG}${hexB}`;

        return hexColor;
      }

    // const NameProject = (url) => {
    //     props.pname (name)
    // }

    const handleTypeChange = (e) => {
        const selectedValue = e.target.value;
        setSelTypes(selectedValue);
        props.pTypesID (selectedValue)
        const selectedText = e.target.options[e.target.selectedIndex].getAttribute('data-text');
        props.pTypes(selectedText);
      };

      const handleCompanyChange = (e) => {
        const selectedValue = e.target.value;
        setSelCompany(e.target.value);
        props.pCompanyID (e.target.value)
        const selectedText = e.target.options[e.target.selectedIndex].getAttribute('data-text');
        props.pCompany(selectedText);
      };

      const handleFileLogo = (e) => {
        const file = e.target.files[0];
    
        if (file) {
            setLogo(file);
            props.nameLogo(file);
            // const temporaryURL = URL.createObjectURL(file);
            // Використовуємо регулярний вираз для видалення всіх <img> тегів
            // const newTextB = sideB.replace(/<img[^>]*>/g, '');

            // const newSideB = newTextB + `<img src="${temporaryURL}">`;
            // const newSideA = newText + `<img src="${file.name}">`;

            // setImgUrlLogo("https://www.nebo.inupline.com/users/" + user_id + "/decks/" + props.mydeck.id + "/" + file.name );
            // setSideB(newSideB);
        }
    };

    const handleFileBG = (e) => {
        const file = e.target.files[0];
    
        if (file) {
            setFondo(file);
            props.nameFondo(file);
        }
    };

    return (
        <div className="form_0">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="userid" readOnly={"user_id"} />
                
                        <div className="input_field"> <span><i aria-hidden="true" className="fa fa-font"></i></span>
                            <input id="title" name="title" type="text" placeholder="NAME" value={name} required onChange={handleNameChange}/>
                        </div>

                        <div className="input_field"> <span className='span'><i aria-hidden="true" className="fa fa-sticky-note"></i></span>
                            <textarea id="note" name="note" type="text" placeholder="Project Note:" value={note} required onChange={handleNoteChange}/>
                        </div>

                        <div className="input_field select_option">
                        <select id="typeid" onChange={handleTypeChange} value={selType}>
                            {misTypes.map((itemg, index) => ( 
                              <option value={itemg.id} key={index} data-text = {itemg.name} >{itemg.name}</option>
                            ))}
                        </select>
                          <div className="select_arrow"></div>
                        </div>

                        <div className="input_field select_option">
                        <select id="code" onChange={handleCompanyChange} value={selCompany}>
                            {misCompany.map((itemg, index) => ( 
                              <option value={itemg.id} key={index} data-text = {itemg.name}>{itemg.name}</option>
                            ))}
                        </select>
                          <div className="select_arrow"></div>
                        </div>

                        <label htmlFor="fileInputLogo" className="input_field btn btn-tertiary js-labelFile">
                            <input
                                type="file"
                                name="fileInputLogo"
                                id="fileInputLogo"
                                accept=".png"
                                onChange={handleFileLogo}
                                style={{
                                    width: "0.1px",
                                    height: "0.1px",
                                    opacity: "0",
                                    border: "1px solid #ccc",
                                    margin: "10px 0",
                                }} 
                            /> 
                            {selLogo ? <b> {selLogo.name} </b> : "Choose LOGO"}
                        </label>

                        Logo color <input id="lurl" name="lurl" type="color" colorformat="rgba" placeholder="Color" value={colorLogo} required onChange={handleColorLogoChange}/>
                            
                        <br /><br />

                        <label htmlFor="fileInputBG" className="input_field btn btn-tertiary js-labelFile">
                            <input
                                type="file"
                                name="fileInputBG"
                                id="fileInputBG"
                                accept=".jpeg, .jpg"
                                onChange={handleFileBG}
                                style={{
                                    width: "0.1px",
                                    height: "0.1px",
                                    opacity: "0",
                                    border: "1px solid #ccc",
                                    margin: "10px 0",
                                }} 
                            /> 
                            {selFondo ? <b> {selFondo.name} </b> : "Choose BACKGROUND"}
                        </label>
                        <br /><br />
                        {/* <img className="plogo" src={URL.createObjectURL(selLogo)} alt="logo" style={{width: "100px", height: "100px"}}/> */}


                        <input className="button" type="submit" value="CREAR"/>
                    </form>
              </div> 
    );
}

function AddProject(props) {
    const [SchoolCards, setCards] = useState([]); // IMPORT DECKS

    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [imgUrlLogo, setImgUrlLogo] = useState(null);
    const [colorLogo, setColorLogo] = useState('#ffffff'); // Valor inicial del color en negro

    const [selectedFondo, setDefaultFondo] = useState(null);
    const [imgUrlFondo, setImgUrlFondo] = useState(null);

    const [misTypesID, setTypesID] = useState([]);
    const [misTypes, setTypes] = useState("project.project_type");
    const [misCompanyID, setCompanyID] = useState([]);
    const [misCompany, setCompany] = useState("project.project_company");

    const [selProjectname, setSelProjectname] = useState("project.project_name");
    const [selProjectnote, setSelProjectnote] = useState("project.project_note");

    const activeProjectId = localStorage.getItem('projectid');

    const login_token = useSelector((state)=> state.counter.user.login_token);
    const { pid, cid } = useParams() // URL parametro  :cr/:pr/:gr/:id
    const navigate = useNavigate();

    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
      };

      const handleUpload = (id) => {
        if (!file) {
          console.error("Файл не вибраний");
          return;
        }
    
        const formData = new FormData();
        formData.append('fileInput', file);
        formData.append('DeckID', cid);
    
        fetch('https://www.nebo.inupline.com/api/web/school/ImportDeck/' + id, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + login_token,
            },
          body: formData,
        })
          .then(response => response.json())
          .then(data => {
            // console.log('Відповідь з сервера:', data);
            // Додаткова обробка відповіді тут
          })
          .catch(error => {
            // console.error('Помилка:', error);
            // Обробка помилок тут
          });
      };

        useEffect(() => {
            handleGetDeck(cid) 
        }, [cid]); // AUTOLOAD

        useEffect(() => {
            if (selectedPhoto) { 
                setImgUrlLogo(URL.createObjectURL(selectedPhoto)) 
                console.log(selectedPhoto)
                console.log(URL.createObjectURL(selectedPhoto))
            }
        }, [selectedPhoto]); // AUTOLOAD

        useEffect(() => {
            if (selectedFondo) { 
                setImgUrlFondo(URL.createObjectURL(selectedFondo)) 
                console.log(selectedFondo)
                console.log(URL.createObjectURL(selectedFondo))
            }
        }, [selectedFondo]); // AUTOLOAD

        const handleGetDeck = (id) => {
            fetch('https://www.nebo.inupline.com/api/web/school/getDeck/' + id, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + login_token,
                },
            })
            .then(response => response.json())
            .then(response => {
                if (response.status===200) {
                    //const updatedProjectURL = [...response.URLs, {"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}];
                    // setLoading(false)
                    // console.log("/Deck-" , response)
                } else {
                    // setProjectURL([{"ico":"add.png","id":"new","info":"asd","title":"ADD","url":"/", "note":""}])
                    // console.log("ERROR Deck-" , response)
                //setLoading(false)
                }
            }); 
        };

        const handleHome = () => {
            navigate(`/`);
        };

        return (
            <div>

            <div className="project_header" style={{padding:`0px`, backgroundSize:`100%`, backgroundColor:"grey",  background:`url(${selectedFondo ? (imgUrlFondo) : (ImgF000)}) center no-repeat`}} >
            <div className='transbox'> 
                <div className="aButtonSetings"><i className="fa fa-cog" aria-hidden="true"></i></div>
                <div className="projectlogo">
                    {selectedPhoto ? (
                    <div className="plogo" style={{width: "100px", height: "100px",backgroundColor: `${colorLogo}`,  WebkitMaskImage: `url(${imgUrlLogo})`, maskImage: `url(${imgUrlLogo})`, WebkitMaskRepeat: "no-repeat", maskRepeat: "no-repeat",WebkitMaskSize:"100%",maskSize:"100%"  }} >
                        </div>
                        ) : ( <img className="plogo" src={tmplogo} alt="logo" style={{width: "100px", height: "100px"}}/>)}
                    </div>

                <div className="label">
                 {misTypes} / {misCompany}
                </div>

                {/* <img className="plogo" src={URL.createObjectURL(imgUrlLogo)} alt="logo" style={{width: "100px", height: "100px"}}/> */}

                <div className="name"  >
                    {selProjectname}
                </div>

                <div className="notes"  >
                    {selProjectnote}
                </div>
            
                <div className="company">
                    {misCompanyID}
                </div>

                <div className="users"  >
                    <i className="fa fa-users" aria-hidden="true"></i> 0   users              
                </div>

                <div className="type"  >
                {misTypesID}           
                </div>

                <div className="loadlogo"  >
                    load img            
                </div>

                <div className="loadimg"  >
                    load logo             
                </div>

                <div className="thema"  >
                    load thema             
                </div>
                </div>
            </div>

                <div>
                    <div className="project_urls"  >
                        <div className="aButtonClassBack" onClick={handleHome}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>
                        <div className="cLabelClassHeaderBar">New Project</div>
                     </div>

                    <DeckForm 
                        pid = {activeProjectId} 
                        pname = {(e) => setSelProjectname(e)} 
                        pnote = {(e) => setSelProjectnote(e)} 
                        nameLogo = {(e) => setSelectedPhoto(e)}
                        colorLogo = {(e) => setColorLogo(e)}
                        nameFondo = {(e) => setDefaultFondo(e)}
                        pTypes = {(e) => setTypes(e)}
                        pTypesID = {(e) => setTypesID(e)}
                        pCompanyID = {(e) => setCompanyID(e)}
                        pCompany = {(e) => setCompany(e)}
                    />
                </div>
        </div>
        );
}

export default AddProject;

