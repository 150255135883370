import React , { useEffect,useState } from 'react';
import './style.css';
import logo from '../img/temp02.png';  //sadmin.png
import {IconsColor} from "../functions/useApiData"

function ProjectInfo(props) {
    const [misTypes, setTypes] = useState([]);
    const [misCompany, setCompany] = useState([]);
    const [misProjects, setProject] = useState([]);
    const [selType, setSelTypes] = useState(misProjects.type_id);
    const [selCompany, setSelCompany] = useState(misProjects.company_id);

    const activeProjectId = localStorage.getItem('projectid');
    const login_token = localStorage.getItem('login_token');

    //const activeProjectId = localStorage.getItem('projectid');
    //const activeProjectId = localStorage.getItem('projectid');


    const [datosCargados, setDatosCargados] = useState(false); // Estado para indicar que los datos están cargados

    const [selLogo, setLogo] = useState(null);
    const [selFondo, setFondo] = useState(null);

    const toggleClass3 = () => {
      const oldClassName = document.getElementById('Menu03').className;
      if (oldClassName === 'menu2open') {
        const newClassName = oldClassName === 'menu2open' ? 'menu2hide' : 'menu2open'
        document.getElementById('Menu03').className=newClassName;
        var workPageNew = "body"
        const workPageOld = document.getElementById('body').className;
        if (workPageOld === 'bodylite' ) workPageNew = 'bodycenter'
        if (workPageOld === 'body' ) workPageNew = 'bodyright'
        if (workPageOld === 'bodyright' ) workPageNew = 'body'
        if (workPageOld === 'bodycenter' ) workPageNew = 'bodylite'
        document.getElementById('body').className=workPageNew;
      }
    }

    useEffect(() => {
          // GET PROJECT
          setDatosCargados(false); // Datos cargados con éxito

          fetch('https://www.nebo.inupline.com/api/web/project/list/' + activeProjectId, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + login_token,
              },
          })
              .then(response => response.json())
              .then(response => {
                  if (response.status===200) {
                    setProject(response.projects[0])
                    setSelTypes(response.projects[0].type_id)
                    setSelCompany(response.projects[0].company_id)

                    setDatosCargados(true); // Datos cargados con éxito
    
                  } else if (response.status===409) {
                    console.log("PROJECT KO-" , response)
                  }
              });  
    }, [activeProjectId]);

    useEffect(() => {
      // перевірити валідність токену

      fetch('https://www.nebo.inupline.com/api/web/project/addtype', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + login_token,
          },
      })
          .then(response => response.json())
          .then(response => {
              
              if (response.status===200) {
                //console.log("type OK-" , response.types)
                setTypes(response.types)

              } else if (response.status===409) {
                console.log("type KO-" , response)
              //   OutME()
              //   setLoading(false)
              }
          });  
    }, []); // AUTOLOAD

    useEffect(() => {
        // перевірити валідність токену
        
            fetch('https://www.nebo.inupline.com/api/web/company/list', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + login_token,
            },
        })
            .then(response => response.json())
            .then(response => {
                
                if (response.status===200) {
                  //console.log("company OK-" , response.company)
                  setCompany(response.company)

                } else if (response.status===409) {
                  console.log("company KO-" , response)
                //   OutME()
                //   setLoading(false)
                }
            });
    }, []); // AUTOLOAD

    useEffect(() => {
      console.log("misProjects",misProjects)
  }, [misProjects]); // AUTOLOAD

   
      const handleTypeChange = (e) => {
        const selectedValue = e.target.value;
        setSelTypes(selectedValue);
      };

      const handleCompanyChange = (e) => {
        const selectedValue = e.target.value;
        setSelCompany(selectedValue);
      };

      const handleSubmit = (e) => {
        e.preventDefault();

        const userData = {
          name: e.target.name.value,
          note: e.target.note.value,
          userid: e.target.userid.value,
          typeid: e.target.typeid.value,
          code: e.target.code.value,
          logo: selLogo,
          fondo: selFondo,
        };

        fetch('https://www.nebo.inupline.com/api/web/project/list/' + activeProjectId, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + login_token,
              },
              body: JSON.stringify(userData),
          })
        .then(response => response.json())
        .then(response => {
            if (response.status===200) {
              console.log("APP->setProjects", response)
              props.update(response)
            }
        });
      };

      const imgLogo = imageName => {

        if (imageName) {
          const reader = new FileReader();
          reader.readAsDataURL(imageName);
    
          reader.onload = () => {
            // Cuando se complete la lectura, obtén la URL base64 de la imagen
            const base64Data = reader.result;
            setLogo(base64Data);
            console.log(base64Data)

            // Luego, envía la imagen al servidor, por ejemplo, utilizando fetch()
            //enviarImagenAlServidor(base64Data);
          };
        }

        //setLogo(imageName);
        //console.log(imageName)
      }

      const imgFondo = imageName => {
        if (imageName) {
          const reader = new FileReader();
          reader.readAsDataURL(imageName);
    
          reader.onload = () => {
            // Cuando se complete la lectura, obtén la URL base64 de la imagen
            const base64Data = reader.result;
            setFondo(base64Data);
            console.log(base64Data)

            // Luego, envía la imagen al servidor, por ejemplo, utilizando fetch()
            //enviarImagenAlServidor(base64Data);
          };
        }
      }

    return (
        <div>
            {datosCargados ? ( <>
                <div className="project_urls"  >
            <div className="aButtonClassBack" onClick={toggleClass3}>{ IconsColor(3, "var(--text_color_menu)","24px") }</div>
            <div className="cLabelClassHeaderBar">PROJECT INFO {misProjects.project_id}</div>
            {/* <div className="aButtonClassAdd" onClick={handleHome}><i className="fa fa-plus-square-o" aria-hidden="true"></i></div>
            <div className="aButtonClassDelete" onClick={() => handleDelDeck(cid)}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
            <div className="aButtonClassImport" onClick={handleHome}><i className="fa fa-upload" aria-hidden="true"></i></div>
            <div className="aButtonClassConfig" onClick={handleHome}><i className="fa fa-cog" aria-hidden="true"></i></div> */}
        </div>
        <div className="form_wrapper">
              <div className="form_container">
                {/* <div className="title_container">
                  <h2>PROJECT / {misProjects.project_id}</h2>
                </div>
                <div className="aButtonClose" onClick={toggleClass3}>x</div> */}

                <div className="row clearfix">
                  <div className="">
                    <form onSubmit={handleSubmit}>

                    <input type="hidden" name="userid" value={"user_id"} />

                    <PhotoComponent nameLogo={imgLogo} nameFondo={imgFondo} pid= {misProjects.project_id} />

                      <div className="input_field"> <span><i aria-hidden="true" className="fa fa-font"></i></span>
                        <input id="name" name="name" type="text" placeholder="Project Name" defaultValue={misProjects.project_name} required/>
                      </div>
                      <div className="input_field"> <span className='span'><i aria-hidden="true" className="fa fa-sticky-note"></i></span>
                        <textarea id="note" name="note" type="text" placeholder="Project Note:" defaultValue={misProjects.project_note} required/>
                      </div>

                        <div className="input_field select_option">
                        <select id="typeid" onChange={handleTypeChange} value={selType}>
                            {misTypes.map((itemg, index) => ( 
                              <option value={itemg.id} key={index}>{itemg.name}</option>
                            ))}
                        </select>
                          <div className="select_arrow"></div>
                        </div>

                        <div className="input_field select_option">
                        <select id="code" onChange={handleCompanyChange} value={selCompany}>
                            {misCompany.map((itemg, index) => ( 
                              <option value={itemg.id} key={index}>{itemg.name}</option>
                            ))}
                        </select>
                          <div className="select_arrow"></div>
                        </div>

                      <input className="button" type="submit" value="SAVE" />
                    </form>
                  </div>
                </div>
              </div>
            </div></>
            ) : (
              <p>Los datos de misProjects están vacíos. </p>
            )}
        </div>
    );
  }
  
export default ProjectInfo;


function PhotoComponent(props) {
  const [defaultPhoto, setDefaultPhoto] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState("/img/projects/" + props.pid + '/logo.png');
  const [defaultFondo, setDefaultFondo] = useState("/img/projects/" + props.pid + '/fondo.jpeg');
  const randomCacheBuster = Math.floor(Math.random() * 10000);


  useEffect(() => {
    const img = new Image();
    img.src = '/img/projects/' + props.pid + '/logo.png';

    img.onload = () => {
      // La imagen existe, no necesitas hacer nada.
      setDefaultPhoto(false);
      setSelectedPhoto('/img/projects/' + props.pid + `/logo.png?cache=${randomCacheBuster}`);
    };

    img.onerror = () => {
      // La imagen no existe, cambia la URL por otra.
      setDefaultPhoto(true);
    };

    
  }, [props.pid]);

  useEffect(() => {
    const img = new Image();
    img.src = '/img/projects/' + props.pid + '/fondo.jpeg';

    img.onload = () => {
      // La imagen existe, no necesitas hacer nada.
      //setDefaultPhoto(false);
      setDefaultFondo('/img/projects/' + props.pid + `/fondo.jpeg?cache=${randomCacheBuster}`);
    };

    img.onerror = () => {
      setDefaultFondo('/img/projects/' + props.pid + `/fondo.jpeg?cache=${randomCacheBuster}`);

      // La imagen no existe, cambia la URL por otra.
      //setDefaultPhoto(true);
    };

    
  }, [props.pid]);


  const handleDefaultPhoto = () => {
    setDefaultPhoto(true);
    setSelectedPhoto(null);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Crear un objeto de imagen para obtener las dimensiones
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = function () {
        const imageSize = file.size; // Tamaño en bytes
        const imageResolution = {
          width: this.width,
          height: this.height,
        };
  
        console.log(`Tamaño: ${imageSize} bytes`);
        console.log(`Resolución: ${imageResolution.width}x${imageResolution.height}`);
  
        // Aquí puedes realizar acciones basadas en el tamaño y la resolución.
  
        setDefaultPhoto(false);
        setSelectedPhoto(URL.createObjectURL(file));
        props.nameLogo(file);
        console.log(defaultFondo);
      };
    }
  };

  const handleFondoInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      //setDefaultPhoto(false);
      setDefaultFondo(URL.createObjectURL(file));
      props.nameFondo(file);
    }
  };

  return (
    <div>
          <div className="pNote" style={{ backgroundImage: `url(${defaultFondo})`, backgroundSize: 'cover' }}>
            <div className="transbox" style={{width:"108%",height:"100%", margin:-10}}>
              <div className="imgButton_load">
                <input
                  type="file"
                  accept=".png, .gif"
                  onChange={handleFileInputChange}
                  style={{ display: 'none' }}
                  name="fileInput"
                  id="fileInput"
                />
                <label htmlFor="fileInput" className="zbutton"><i className="fa fa-crosshairs" aria-hidden="true">L</i>
              </label>
              <input
                  type="file"
                  accept=".jpeg, .jpg"
                  onChange={handleFondoInputChange}
                  style={{ display: 'none' }}
                  name="FondoInput"
                  id="FondoInput"
                />
                <label htmlFor="FondoInput" className="zbutton"><i className="fa fa-picture-o" aria-hidden="true">B</i></label>

                <div className="zbutton" onClick={handleDefaultPhoto}><i className="fa fa-undo" aria-hidden="true">D</i></div>

              </div>
            {defaultPhoto ? (
              <img className="aLogo"
              src={logo} // Замініть на свій шлях до фото за замовчуванням
              alt="Default Photo"
              />
            ) : (
              <img className="aLogo" src={selectedPhoto} alt="Selected Photo" />
            )}
      </div>
      <div>
      </div>


      </div>
    </div>
  );
}